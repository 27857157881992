// import React, { useEffect, useState } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import Interface from "../../Rcompo/Interface";
// import axios from "axios";
// import globalAPIAddress from "../GlobalPages/GlobalVar";

// export default function MyShipment({ children }) {
//   const [listedCount, setListedCount] = useState(0);
//   const [assignedCount, setAssignedCount] = useState(0);
//   const [archieveCount,setArchieveCount]=useState(0)
//   const [dispatchCount,setDispatchCount]=useState(0)
//   const[pickedupCount,setPickedupCount]=useState(0)

//   const [activeLink, setActiveLink] = useState(() => {
//     // Get the active link from localStorage, or default to "Not Signed"
//     return localStorage.getItem("activeLink") || "Not Signed";
//   });
//   const navigate = useNavigate();

//   const handleOptionChange = (selectedOption) => {
//     setActiveLink(selectedOption);
//     navigate(`/${selectedOption}`);

//     // Save the active link to localStorage
//     localStorage.setItem("activeLink", selectedOption);
//   };
  
//   const listofbuttons = ["Listed", "Not Signed", "Dispatched", "PickUp", "Delivered", "Cancelled", "Archieve"];

//   useEffect(() => {
//     axios.get(`${globalAPIAddress}/count`)
//       .then(response => {
//         const { listedCount, assignedCount,archieveCount,dispatchCount,pickedupCount } = response.data;
//         setListedCount(listedCount); // Set listedCount with the received value
//         setAssignedCount(assignedCount);
//         setDispatchCount(dispatchCount )
//         setPickedupCount(pickedupCount)
//         setArchieveCount(archieveCount); // Set assignedCount with the received value
//          // Set assignedCount with the received value
//       })
//       .catch(error => {
//         console.error('Error fetching counts:', error);
//       });
//   }, []); // Empty dependency array to run effect only once when component mounts

//   return (
//     <Interface>
//       <div className=" mt-14 mx-7 md:mx-10 lg:mx-36">
//         <div className="px-6 bg-white rounded-lg py-4 font-semibold text-xl shadow-md shadow-gray-300 mb-8">
//           <span className="font-semibold text-xl">My Vehicles</span>
//           {/* Flex */}
//           <div className=" mt-5 font-sm hidden lg:flex xl:flex items-center ">
//             {listofbuttons.map((option, index) => (
              
//               <Link
//                 key={index}
//                 onClick={() => handleOptionChange(option)}
//                 to={`/${option}Shipper`}
//                 className={`mx-3 p-2 text-sm rounded-sm ${
//                   activeLink === option ? "bg-red-600 text-white" : "bg-gray-300"
//                 }`}
//               >
//                 {/* Use listedCount and assignedCount here */}
//                 {option === "Listed" ? `Listed (${listedCount})` : option === "Not Signed" ? `Not Signed (${assignedCount})` : option === "Archieve" ? `Archieve (${archieveCount})`: option === "Dispatched" ? `Dispatched (${dispatchCount})`: option === "PickUp" ? `PickUp (${pickedupCount})`: option}
//               </Link>
//             ))}
//           </div>
//           <div className="mt-5 font-sm w-full py-3 flex lg:hidden xl:hidden items-center justify-around">
//             <select
//               className="w-full focus:outline-none border-2 border-gray-300 rounded-lg"
//               onChange={(e) => handleOptionChange(e.target.value)}
//               value={activeLink}
//             >
//               {listofbuttons.map((option, index) => (
//                 <option key={index} value={option}>
//                   {/* Use listedCount and assignedCount here */}
//                   {option === "ListedShipper" ? `Listed (${listedCount})` : option === "Not SignedShipper" ? `Not Signed (${assignedCount})` : option === "ArchieveShipper" ? `Archieve (${archieveCount})` : option === "DispatchedShipper" ? `Dispatched (${dispatchCount})`: option === "PickUpShipper" ? `PickUp (${pickedupCount})`: option}

//                 </option>
//               ))}
//             </select>
//           </div>
//           {/* Flex */}
//         </div>

//         <div>{children}</div>
//       </div>
//     </Interface>
//   );
// }

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Interface from "../../Rcompo/Interface";
import axios from "axios";
import globalAPIAddress from "../GlobalPages/GlobalVar";

export default function MyShipment({ children }) {
  const [listedCount, setListedCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);
  const [archieveCount, setArchieveCount] = useState(0);
  const [dispatchCount, setDispatchCount] = useState(0);
  const [pickedupCount, setPickedupCount] = useState(0);
  const [DeliveredCount, setDeliveredCount] = useState(0);
  const [CanceCount, setCanceCount] = useState(0);
  const [activeLink, setActiveLink] = useState(() => {
    return localStorage.getItem("activeLink") || "Not Signed";
  });
  const navigate = useNavigate();

  const handleOptionChange = (selectedOption) => {
    setActiveLink(selectedOption);
    navigate(`/${selectedOption}Shipper`);
    localStorage.setItem("activeLink", selectedOption);
  };

  const listofbuttons = [
    "Listed",
    "Not Signed",
    "Dispatched",
    "PickUp",
    "Delivered",
    "Cancelled",
    "Archieve",
  ];

  useEffect(() => {
    axios
      .get(`${globalAPIAddress}/Shippercount`)
      .then((response) => {
        const {
          listedCount,
          assignedCount,
          archieveCount,
          dispatchCount,
          pickedupCount,
          CanceCount,
          DeliveredCount
        } = response.data;
        setListedCount(listedCount);
        setAssignedCount(assignedCount);
        setDispatchCount(dispatchCount);
        setPickedupCount(pickedupCount);
        setArchieveCount(archieveCount);
        setDeliveredCount(DeliveredCount);
        setCanceCount(CanceCount);
      })
      .catch((error) => {
        console.error("Error fetching counts:", error);
      });
  }, []);

  return (
    <Interface>
      <div className="mt-14 mx-7 md:mx-10 lg:mx-36">
        <div className="px-6 bg-white rounded-lg py-4 font-semibold text-xl shadow-md shadow-gray-300 mb-8">
          <span className="font-semibold text-xl">My Vehicles</span>
          <div className="mt-5 font-sm hidden lg:flex xl:flex items-center ">
            {listofbuttons.map((option, index) => (
              <Link
                key={index}
                onClick={() => handleOptionChange(option)}
                to={`/${option}Shipper`}
                className={`mx-3 p-2 text-sm rounded-sm ${
                  activeLink === option ? "bg-red-600 text-white" : "bg-gray-300"
                }`}
              >
                {option === "Listed"
                  ? `Listed (${listedCount})`
                  : option === "Not Signed"
                  ? `Not Signed (${assignedCount})`
                  : option === "Archieve"
                  ? `Archived (${archieveCount})`
                  : option === "Dispatched"
                  ? `Dispatched (${dispatchCount})`
                  : option === "PickUp"
                  ? `PickUp (${pickedupCount})`
                  : option === "Delivered"
                  ? `Delivered (${DeliveredCount})`
                  : option === "Cancelled" 
                  ? `Cancelled (${CanceCount})`
                  : option}
              </Link>
            ))}
          </div>
          <div className="mt-5 font-sm w-full py-3 flex lg:hidden xl:hidden items-center justify-around">
            <select
              className="w-full focus:outline-none border-2 border-gray-300 rounded-lg"
              onChange={(e) => handleOptionChange(e.target.value)}
              value={activeLink}
            >
              {listofbuttons.map((option, index) => (
                <option key={index} value={option}>
                  {option === "Listed"
                    ? `Listed (${listedCount})`
                    : option === "Not Signed"
                    ? `Not Signed (${assignedCount})`
                    : option === "Archived"
                    ? `Archived (${archieveCount})`
                    : option === "Dispatched"
                    ? `Dispatched (${dispatchCount})`
                    : option === "PickUp"
                    ? `PickUp (${pickedupCount})`
                    : option === "Delivered" // Added condition for Delivered
                    ? `Delivered (${DeliveredCount})`
                    : option === "Cancelled" // Added condition for Cancelled
                    ? `Cancelled (${CanceCount})`
                    : option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </Interface>
  );
}

