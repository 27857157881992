// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import globalAPIAddress from "../GlobalPages/GlobalVar";
// const Company = () => {
//   const { compId } = useParams();

//   const [company, setCompany] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchCompany = async () => {
//       try {
//         const response = await axios.get(`${globalAPIAddress}/Company/${compId}`);
//         alert(response.data)
//         setCompany(response.data);
//         setLoading(false);
//       } catch (error) {
//         console.error(error);
//         // Handle error (e.g., display error message to user)
//       }
//     };

//     fetchCompany();
//   }, [compId]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (!company) {
//     return <div>Company not found</div>;
//   }

//   return (
//     <div className=" p-4  ">
//       <h2 className="text-xl font-bold bg-gray-100 rounded-lg p-4 mb-4">{company.companyname}</h2>
//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Contact Information</h3>
//         <p><strong>Address:</strong> {company.contact_information.address}</p>
//         <p><strong>Phone Number:</strong> {company.contact_information.phone_number}</p>
//         <p><strong>Email:</strong> {company.contact_information.email}</p>
//       </div>

//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Company Structure</h3>
//         <p><strong>Type:</strong> {company.company_structure.type}</p>
//         <p><strong>Registration Number:</strong> {company.company_structure.registration_number}</p>
//       </div>

//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Operational Details</h3>
//         <p><strong>Service Area:</strong> {company.operational_details.service_area}</p>
//         <p><strong>Services Offered:</strong> {company.operational_details.services_offered.join(', ')}</p>
//         <p><strong>Hours of Operation:</strong> {company.operational_details.hours_of_operation}</p>
//       </div>

//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Insurance Information</h3>
//         <p><strong>Liability Insurance:</strong> {company.insurance_information.liability_insurance}</p>
//       </div>

//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Fleet Information</h3>
//         <p><strong>Number of Vehicles:</strong> {company.fleet_information.number_of_vehicles}</p>
//         <p><strong>Vehicle Types:</strong> {company.fleet_information.vehicle_types.join(', ')}</p>
//       </div>

//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Billing and Payment Details</h3>
//         <p><strong>Payment Methods:</strong> {company.billing_payment_details.payment_methods.join(', ')}</p>
//       </div>

//       <div className="bg-gray-100 rounded-lg p-4 mb-4">
//         <h3 className="text-lg font-semibold mb-2">Emergency Contact Information</h3>
//         <p><strong>Emergency Contact Person:</strong> {company.emergency_contact_information.emergency_contact_person}</p>
//         <p><strong>Emergency Contact Number:</strong> {company.emergency_contact_information.emergency_contact_number}</p>
//       </div>
//     </div>
//   );
// };

// export default Company;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import globalAPIAddress from "../GlobalPages/GlobalVar";
import Navbar from '../CommanPages/Navbar';
import Sidebar from '../CommanPages/Sidebar';
export default function CompanyProfile() {
  const { compId } = useParams();

  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/Company/${compId}`);
        alert(response.data)
        setCompany(response.data);
        setLoading(false);
      } catch (error) {
        alert(error)
        console.error(error);
        // Handle error (e.g., display error message to user)
      }
    };

    fetchCompany();
  }, [compId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!company) {
    return <div>Company not found</div>;
  }

  
  return (
    // <Interface>
    <>
    <Navbar/>
    {/* <Sidebar/> */}
        <div className=" mx-7 md:mx-10   lg:mx-4 lg:px-8">
          <div className="text-xl sm:text-xl lg:text-3xl mt-6 mb-4 ml-8">
            Company Profile
          </div>
          <div className="border  w-full h-fit mt-6  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-2 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Account Information</p>
            </div>
            <div className="px-4 py-4">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company ID:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.company}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Marketplace(s):{" "}
                </span>
                <span className="text-sm lg:text-md"> {company.marketplaces}</span>
              </div>

              <div className="flex justify-between mb-2">
                <div>
                  <span className="lg:text-md text-sm ml-4 font-semibold">
                    Password:{" "}
                  </span>
                  <span className="text-sm lg:text-md">{company.password}</span>
                </div>
                <div>
                  <div>
                    <button className="text-blue-900 text-xs mr-6 font-bold hover:underline">
                      [ Change Password ]
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Business Type:
                </span>
                <span className="text-sm lg:text-md"> {company.businessType}</span>
              </div>

              <div className="mb-6">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Rating Access:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.ratingAccess}</span>
              </div>
            </div>
          </div>

          <div className="border  w-full h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Company Information</p>
            </div>
            <div className="py-4 px-4">
              <div className="mb-3">
                <p className="text-sm lg:text-md ml-4 font-semibold">
                {company.address} 
                </p>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Owner/Manager:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.ownerManager}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Website:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.website}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Hours:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.hours}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Timezone:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.timezone}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Established:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.established}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company Description:{" "}
                </span>
                <span className="text-sm lg:text-md"> {company.description}</span>
              </div>
            </div>
          </div>

          <div className="border  w-full h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Contact Information</p>
            </div>
            <div className="px-4 py-4">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Contact:{" "}
                </span>
                <span>{company.dispatchContact}</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Preferred Contact Method:{" "}
                </span>
                <span>{company.preferredContactMethod}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Listings:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.listings}</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Local:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.local}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Email:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.email}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Ratings Emails at:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.ratingsEmailsAt}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Emails at:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.dispatchEmailsAt}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company Description:{" "}
                </span>
                <span className="text-sm lg:text-md"> {company.ownerManager}</span>
              </div>
            </div>
          </div>

          <div className="border w-full mb-8 h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit py-4 px-4 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Reference Information</p>
            </div>
            <div className="mb-2 px-4 py-4">
              <span className="text-sm lg:text-md ml-4 font-semibold">
                Driving Liscense #:{" "}
              </span>
              <span className="text-sm lg:text-md"> {company.drivingLicenseNumber}</span>
            </div>
          </div>

          <div className="border  w-full mb-8 h-fit mt-4  bg-white  shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4 bg-slate-300  rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Billing Information</p>
            </div>
            <div className="px-4 py-4">
              <div className="mb-3">
                <p className="text-sm lg:text-md ml-4 font-semibold">
              {company.name}
                </p>
                
                <p className="text-sm lg:text-md ml-4">
                {company.personalAddress}
                </p>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Phone:{" "}
                </span>
                <span className="text-sm lg:text-md"> {company.personalPhone}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Driving Liscense #:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.ownerManager}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Emails at:{" "}
                </span>
                <span className="text-sm lg:text-md">{company.ownerManager}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    // </Interface>
  );
}