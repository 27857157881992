import React, { useEffect, useState } from "react";
import Interface from "../../Rcompo/Interface";
import axios from "axios";
import globalAPIAddress from "../GlobalPages/GlobalVar";
export default function InterestedDrivers() {
  const handleIconClick = (icon) => {
    alert(`Clicked ${icon} button`);
  };
  const handleIconClick1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setCallNowVisible(!isCallNowVisible);
  };
  const [activeLink, setActiveLink] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCallNowVisible, setCallNowVisible] = useState(true);
  const [items,setItems]=useState([])
  
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.post(`${globalAPIAddress}/InterestedDrivers`);
      setItems(response.data);
        console.log(response.data)
      } catch (error) {
        console.error(error);
      }
    };
    fetchItems();
  }, []);
  return (
    <Interface>
      <div className="h-full  mx-7 md:mx-10 lg:mx-32">
        <div className="mt-6 px-3 py-2 lg:mt-10 lg:mb-10 shadow-2xl shadow-gray-300 lg:mx-10 lg:px-6 bg-white rounded-lg lg:py-4">
          <span className="font-semibold text-xl">
            All Available Listings (0)
          </span>
          <div className="mt-5 ">
            {/* Flex */}
            <div className="px-3 lg:pl-6 font-sm flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center lg:justify-around">
              <div className="flex">
                <span>Sort by pickup-location</span>
                {/* Sort By  */}

                <button
                  onClick={() => handleIconClick("Pick-Up Location")}
                  className="ml-2 flex items-center px-3 lg:px-2 py-0 bg-gray-300 rounded "
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                <div className=" ml-2">
                  <button
                    onClick={() => handleIconClick("Up Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>

                <div className="ml-1">
                  <button
                    onClick={() => handleIconClick("Down Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1"
                    />
                  </button>
                  {/* Arrows with the Images and Background */}
                </div>
              </div>
              <div className="pl-0 lg:pl-6 font-sm flex items-center">
                <span>thenby</span>
                {/* Sort By  */}
                <button
                  onClick={() => handleIconClick("Delivery Metro aria")}
                  className="ml-2 flex items-center px-2   py-0 bg-gray-300 rounded "
                >
                  DeliveryMetroaria
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                <div className=" ml-2">
                  <button
                    onClick={() => handleIconClick("Up Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>

                <div className="ml-1">
                  <button
                    onClick={() => handleIconClick("Down Arrow")}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/down-arrow-red.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1"
                    />
                  </button>
                  {/* Arrows with the Images and Background */}
                </div>
              </div>
              {/* Up Arrow ANd Down Arrow After the Aria */}

              <div className="pl-0 lg:pl-12 font-sm flex items-center">
                <span>View</span>
                {/* View */}
                <a>
                  <img
                    src={process.env.PUBLIC_URL + "/images/grid-red.png"}
                    alt="Image1"
                    className="w-6 h-6 ml-1"
                  />
                </a>
                {/* Grid Image*/}
                <a>
                  <img
                    src={process.env.PUBLIC_URL + "/images/brochure.png"}
                    alt="Image1"
                    className="w-5 h-5 ml-1"
                  />
                </a>
                {/* 2nd Grid Image*/}
              </div>

              <div className="pl-0 lg:pl-11 font-sm flex items-center">
                <span>Per Page</span>
                {/* Per Page */}
                <button className="ml-2 flex items-center px-1 py-0 bg-gray-300 rounded ">
                  50
                  <img
                    src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
                    alt="Image1"
                    className="w-3 h-3 ml-1"
                  />
                  {/* button Pickup */}
                </button>
                {/* Per Page */}
              </div>
            </div>
            {/* Flex */}
          </div>
        </div>
        {/* popup container */}
        {items
          .slice()
          .reverse()
          .map((item) => (
          <div
            className={
               "bg-white mt-4 lg:mt-7 mx-0 mb-8 lg:mb-16 h-fit lg:h-56 rounded-lg py-3 lg:py-8 overflow-hidden shadow-lg lg:mx-10"
            }
          >
            {/* Miles Amount Div Dummy */}
            <div className="flex lg:-space-x-20 justify-around flex-row lg:flex-row">
              <div className="flex flex-col md:flex-row lg:flex-row">
                <div className="lg:mt-9 ml-6 ">
                  <span className="text-2xl lg:text-5xl font-bold">
                    $3
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    0000 mi @ $0.00/mi
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Cash/Certified
                  </span>
                  {/* Miles Amount Div Dummy */}
                </div>
                {/* Vehicle Info */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-0">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Vehicle Info
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg">{item.Year}</span><br></br>
                  <span className="text-sm lg:text-lg">{item.Make}</span><br></br>
                  <span className="text-sm lg:text-lg">{item.Model}</span><br></br>

                  <span className="text-sm lg:text-lg text-slate-400">
                  Dimension
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                  Operable
                  </span>

                </div>
                {/* Vehicle Info */}

                {/* Company Details And Ratings */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-0">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Vin
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                  {item.Vin}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                  Reg_No
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                  {item.Reg_No}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                  Truck Size
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                  {item.TSize}
                  </span>
          
                
                </div>
                {/* Company Details And Ratings */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-0">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Company
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                  {item.company}
                  </span>
                  {/* PickUp-Location And Delivery Location And View Route */}
                </div>
                {/* PickUp-Location And Delivery Location And View Route */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-0">
                  <span className="text-sm lg:text-lg text-slate-400">
                    PickUp-Location
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                   {item.Location}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600 mt-2 font-bold">
                    View Route
                  </span>
                  {/* PickUp-Location And Delivery Location And View Route */}
                </div>
              </div>
              <div >
                <div className="flex flex-col lg:flex-row ml-6 mt-4 lg:ml-0 lg:mt-20">
                  <button className=" lg:mx-1 my-1 flex items-center px-2 py-2 bg-slate-200 hover:bg-red-600 rounded ">
                    <span className="hover:text-white">Call Now</span>
                  </button>
                  <button className="lg:mx-1 my-1 flex items-center px-2 py-2 bg-red-600 rounded ">
                    <span className="text-white">Interested</span>
                  </button>
                </div>
                {/* Call Now Button */}
                {/* <div className='ml-6 mt-20'>
            {isCallNowVisible && (
              <button onClick={() => handleIconClick('Pick-Up Location')} className="ml-2 flex items-center px-2 py-0 bg-red-600 rounded ">
                <span className='text-white'>Call Now</span>

              </button>
            )}
            </div> */}
                {/* Call Now Button */}

                {/* Up Arrow ANd Down Arrow After Call Now*/}
          
              </div>

              {/* Flex for Content under */}
            </div>
            {/* State For DropDown */}
            {/* Lower Container With Infoemation */}
     
          </div>
        ))}
      </div>
    </Interface>
  );
}
