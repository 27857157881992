import axios from 'axios';
import React,{useState} from 'react'
import { Link } from 'react-router-dom';
import globalAPIAddress from '../Components/GlobalPages/GlobalVar';

export default function Notification(props) {
    const handleIconClick = (icon) => {
        alert(`Clicked ${icon} button`);
      };
      const handleIconClick1 = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setCallNowVisible(!isCallNowVisible);
      };
      const [activeLink, setActiveLink] = useState(false);
      const [isDropdownOpen, setIsDropdownOpen] = useState(false);
      const [isCallNowVisible, setCallNowVisible] = useState(true);
      const [interested, setInterested] = useState([]);
      const [isInterested, setIsInterested] = useState(true);
      const [sendVinToBackend, setSendVinToBackend] = useState(true);
      const BtnClicked = () => {
        setIsInterested(!isInterested);
        const isInterestedIndex = interested.findIndex(item => item.ShipID === props.ShipID && item.Vin === props.Vin);
      
        if (isInterestedIndex === -1) {
          console.log(props.ShipID);
          setInterested(prevInterested => [...prevInterested, { ShipID: props.ShipID, Vin: props.Vin, DriverID: props.DriverID }]);
        } else {
          console.log(
            "User",
            props.DriverID,
            "already marked as interested for Ship ID:",
            props.ShipID,
            "and VIN:",
            props.Vin
          );
          console.log(interested);
          const updatedInterested = [...interested];
          updatedInterested.splice(isInterestedIndex, 1);
          setInterested(updatedInterested);
        }
        if (sendVinToBackend) {
          axios.post(`${globalAPIAddress}/Abcd`, { vin: props.Vin, did:  props.DriverID, sid: props.ShipID })
            .then(response => {
              console.log('VIN number sent to backend successfully:', response.data);
            })
            .catch(error => {
              console.error('Error sending VIN number to backend:', error);
            });
        }
        setSendVinToBackend(prevState => !prevState);
    
      };
        

  return (
    <div>
          <div
            className={
              activeLink === true
                ? "mb-16 mt-4 lg:mt-7 h-fit rounded-lg overflow-hidden outer-container shadow-lg pt-3 lg:pt-8 border-black border bg-white"
                : "bg-white mt-4 lg:mt-7 mx-0 mb-8 lg:mb-16 h-fit lg:h-56 rounded-lg py-3 lg:py-8 overflow-hidden shadow-lg "
            }
          >
            {/* Miles Amount Div Dummy */}
            <div className="flex lg:-space-x-20 justify-around flex-row lg:flex-row">
              <div className="flex flex-col md:flex-row lg:flex-row">
                <div className="lg:mt-12 ml-6 ">
                  <span className="text-2xl lg:text-5xl font-bold">
                    ${props.PTC}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    0000 mi @ $0.00/mi
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Cash/Certified
                  </span>
                  {/* Miles Amount Div Dummy */}
                </div>
                {/* Vehicle Info */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-4">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Vehicle Info
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg">{props.Make} </span><br></br>
                  <span className="text-sm lg:text-lg">{props.Model} </span>
                  <span className="text-sm lg:text-lg">{props.Year} </span><br></br>
                </div>
                {/* Vehicle Info */}

                {/* Company Details And Ratings */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-4">
                  <span className="text-sm lg:text-lg text-slate-400">
                    Company
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    {props.company}
                  </span>
                </div>
                {/* Company Details And Ratings */}

                {/* PickUp-Location And Delivery Location And View Route */}
                <div className="ml-6 mt-4 lg:ml-12 lg:mt-4">
                  <span className="text-sm lg:text-lg text-slate-400">
                    PickUp-Location
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    {props.Location}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Delivery-Location
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                  {props.Destination}
                  </span>
                  <br></br>

                  <span className="text-sm lg:text-lg text-red-600 mt-2 font-bold">
                    View Route
                  </span>
                  {/* PickUp-Location And Delivery Location And View Route */}
                </div>
              </div>
              <div className="flex flex-col md:flex-row lg:flex-row">
                <div className="ml-6 lg:ml-12 lg:mt-4">
                  <span className="text-sm lg:text-lg text-slate-400">
                    PickUp on or After Date
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    {props.To}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Desired Delivery Date
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-red-600">
                    {props.From}
                  </span>
                  <br></br>
                  <span className="text-sm lg:text-lg text-slate-400">
                    Posted Date
                  </span>
                  {/* PickUp-Location And Delivery Location And Posted Date*/}
                  <p>{props.cp}</p>
                </div>

                <div className="ml-6 mt-4 lg:ml-6 lg:mt-20">
                  <button className=" lg:mx-1 my-1 flex propss-center px-2 py-2  bg-red-600 rounded ">
                    <span className="text-white">Call Now</span>
                  </button>
                  {/* <Link to="/InterestedDrivers"> */}
                    <button onClick={BtnClicked}
                    className=" my-2 py-1  lg:my-1 flex propss-center lg:px-2 lg:py-2 bg-red-600 rounded ">
                       <span className="text-white">{isInterested ? 'Interested' : 'NotInterested'}</span>
                    </button>
                  {/* </Link> */}
                </div>
                <div className="ml-6 mt-4 lg:ml-8 lg:mt-20">
                  <button
                    onClick={() => {
                      handleIconClick1();
                      setActiveLink(!activeLink);
                    }}
                    className="icon-button"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/up-arrow-white.png"
                      }
                      alt="Image1"
                      className="w-5 h-5 ml-1  rounded-full bg-red-500 color-white"
                    />
                  </button>
                </div>
              </div>

              {/* Flex for Content under */}
            </div>
            {/* State For DropDown */}
            {/* Lower Container With Infoemation */}
            {isDropdownOpen && (
              <div className="mt-10 mb-6 inner-container bg-white">
                <div className="h-fit border-t-2  border-gray-200 mx-10 pt-8">
                  <div className="flex">
                    {/* Flex */}
                    <div className="ml-8 mt-4">
                      {/* GENERAL INFO Button */}
                      <button
                        onClick={() => handleIconClick("General Info")}
                        className="ml-2  px-3 py-1 bg-red-600 rounded "
                      >
                        <span className="text-white">GENERAL INFO</span>
                      </button>
                      {/* GENERAL INFO Button */}
                    </div>
                    <div className="ml-8 mt-4">
                      {/* PRICE COMPARE Button */}
                      {/* <button
                        onClick={() => handleIconClick("General Info")}
                        className="ml-2  px-3 py-1 bg-gray-100 rounded "
                      >
                        <span className="">PRICE COMPARE</span>
                      </button> */}
                      {/* PRICE COMPARE Button */}
                    </div>
                    <div className="ml-8 mt-4">
                      {/* GENERAL INFO Button */}
                      <button
                        onClick={() => handleIconClick("General Info")}
                        className="ml-96 content-end px-3 py-1 bg-red-600 rounded "
                      >
                        <span className="text-white">CALL NOW</span>
                      </button>
                      {/* GENERAL INFO Button */}
                    </div>
                    {/* display Flex: */}
                  </div>
                  {/* VEHICLE INFO IN DETAILS */}
                  <div className="flex">
                    <div className="mt-8 ml-10">
                      <span className="">VEHICLE INFO</span>
                      <br></br>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Vehicle</span>
                        <br></br>
                        <span >{props.Model}{"//"} {props.Make}{"//"} {props.Year} </span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Vin No.</span>
                        <br></br>
                        <span>{props.Vin}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Weight</span>
                        <br></br>
                        <span></span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Dimensions</span>
                        <br></br>
                        <span>{props.Operatble}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      

                      <div className="mt-4 mb-4">
                        {/* Company Info 1st Company Name */}
                        <span className="text-slate-400">Important Notes</span>
                        <br></br>
                        <span className="text-red-600">{props.TransportType}</span>
                      </div>
                    </div>
                    {/* VEHICLE INFO IN DETAILS */}

                    {/* COMPANY INFO IN DETAILS */}
                    <div className="mt-8 ml-20">
                      <span className="">COMPANY INFO</span>
                      <br></br>
                      <div className="mt-4">
                        <span className="text-slate-400">Company Name</span>
                        <br></br>
                        <span className="text-red-600">{props.company}</span>
                      </div>
                      <div className="mt-4">
                        <span className="text-slate-400">Phone Number</span>
                        <br></br>
                        <span className="text-red-600">{props.Number}</span>
                      </div>
                      <div className="mt-4">
                        <span className="text-slate-400">Hours</span>
                        <br></br>
                        <span className="text-red-600"></span>
                      </div>
                      <div className="mt-4">
                        <span className="text-slate-400">
                          Rating In Old System
                        </span>
                        <br></br>
                        <span></span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>

                      <div className="mt-4 mb-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Overall Ratings</span>
                        <br></br>
                        <span className=""></span>
                      </div>
                    </div>
                    {/* COMPANY INFO IN DETAILS */}

                    {/* JOB INFO IN DETAILS */}
                    <div className="mt-8 ml-20">
                      <span className="">JOB INFO</span>
                      <br></br>
                      <div className="mt-4">
                        <span className="text-slate-400">Origin</span>
                        <br></br>
                        <span className="text-red-600">{props.Location}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Destination</span>
                        <br></br>
                        <span className="text-red-600">{props.Destination}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Price</span>
                        <br></br>
                        <span className="">${props.PTC}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>

                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Order ID</span>
                        <br></br>
                        <span>000-00</span>
                      </div>
                    </div>
                    {/* JOB INFO IN DETAILS */}

                    {/* DATES IN DETAILS */}
                    <div className="mt-8 ml-20">
                      <span className="">DATES</span>
                      <br></br>
                      <div className="mt-4">
                        <span className="text-slate-400">
                          Pick-up on or After Date
                        </span>
                        <br></br>
                        <span className=" font-bold">{props.To}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">
                          Desired Delivery Date
                        </span>
                        <br></br>
                        <span className="font-bold">{props.From}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Posted Date</span>
                        <br></br>
                        <span className="">{props.cp}</span>
                        {/* Vehicle Info 1st Vehicle Name */}
                      </div>
                      {/* DATES IN DETAILS */}
                    </div>
                    {/* MISC IN DETAILS */}
                    <div className="mt-8 ml-12">
                      <span className="">MISC</span>
                      <br></br>
                      <div className="mt-4">
                        {/* Vehicle Info 1st Vehicle Name */}
                        <span className="text-slate-400">Additional Info</span>
                        <br></br>
                        <span className=" font-bold">Abcd</span>
                      </div>
                      {/* MISC IN DETAILS */}
                    </div>
                    {/* Flex for Lower Content */}
                  </div>
                </div>
              </div>
            )}
          </div>
    </div>
  )
}