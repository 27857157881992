import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DriverInterface from "../../Rcompo/DriverInterface";
import globalAPIAddress from "../GlobalPages/GlobalVar";
import axios from "axios";

export default function Cars({
  head1,
  Opt,
  label3,
  label4,
  label5,
  label6,
  label7,
  label8,
  Year,
  Make,
  Model,
  buttonLabel1,
}) {
  const [Yearss, setYear] = useState("");
  const [Modelss, setModel] = useState("");
  const [Makess, setMake] = useState("");
  const [Location, setLocation] = useState("");
  const [VIN, setVIN] = useState("");
  const [RegNo, setRegNo] = useState("");
  const [TSize, setTSize] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [Operable, setOperable] = useState(null);
  
  const ChangeOperable = (value) => {
    setOperable(value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = () => {
    // Perform upload logic here (e.g., send selectedFile to server)
    console.log("File uploaded:", selectedFile);
    // Reset selected file after upload
    setSelectedFile(null);
  };
  const HandleSubmit = () => {
    axios.post(`${globalAPIAddress}/PostVehicle`,
    {
      year: Yearss,
      make: Makess,
      model : Modelss, 
      vin: VIN, 
      regNo: RegNo,
      tSize: TSize,
      operable: Operable,
      location:Location
    }
    ).then(response => {
      setTimeout(() => {
        if(response.status === 200)
        {
        toast.success("Shipment Saved SuccessFully");
        console.log(response.data.message);
        }
        else if(response.status === 400)
        {
        toast.error("ajsksk");
        // console.log(response.data.message);
        }
        }, 1000); 
    }).catch(error => {
         console.log(error.response.data); 
    });
    }
  return (
    <DriverInterface>
      <div className="mx-7 md:mx-10   lg:mx-36 px-4 lg:px-10 py-4 lg:py-5 max-w-full mt-4 lg:mt-10 mb-8 lg:mb-24 bg-white shadow-2xl shadow-gray-300 rounded-md lg:rounded-lg">
          <ToastContainer position="top-center" reverseOrder={false} autoClose={2000}/>
        <h2 className="font-semibold text-xl mb-4">{head1}</h2>
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="pr-4">
              <div className="mb-4">
                <label
                  htmlFor="dropdown1"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label3}
                </label>
                <select
                  id="dropdown1"
                  className=" w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={Yearss}
                  onChange={(e)=>{
                    setYear(e.target.value)
                  }}
                >
                  <option value="">Select a {label3}</option>
                  {Year.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Text"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label4}
                </label>
                <select
                  id="Text"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={Modelss}
                  onChange={(e)=>{
                    setModel(e.target.value)
                  }}
                >
                  <option value="">Select a {label4}</option>
                  {Model.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="dropdown3"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  {label5}
                </label>
                <select
                  id="dropdown3"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={Makess}
                  onChange={(e)=>{
                    setMake(e.target.value)
                  }}
                >
                  <option value="">Select a {label5}</option>
                  {Make.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="dropdown3"
                  className="block mb-1 font-semibold text-gray-700"
                >
                  Location
                </label>
                <select
                  id="dropdown3"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value="Location"
                  onChange={(e)=>{
                    setLocation(e.target.value)
                  }}
                >
                  <option value="">Select a Location</option>
                  {Opt.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-span-1 space-y-4">
            <div>
              <label className="block font-semibold text-gray-700 ">
                {label6}
                <br></br>
                <input
                  className="w-auto   mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  // style={{width:'500px'}}
                  type="text"
                  placeholder="1727"
                  onChange={(e)=>{
                    setVIN(e.target.value)
                  }}
                />
              </label>
            </div>
            <div>
              <label className="  font-semibold text-gray-700 ">
                {label7}
                <br></br>
                <input
                  className="w-auto   mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  // style={{width:'500px'}}
                  type="text"
                  placeholder="547263463"
                  onChange={(e)=>{
                    setRegNo(e.target.value)
                  }}
                />
              </label>
            </div>
            <div>
              <label className="block font-semibold text-gray-700 ">
                {label8}
                <br></br>
                <input
                  className="w-auto   mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  // style={{width:'500px'}}
                  type="text"
                  onChange={(e)=>{
                    setTSize(e.target.value);
                  }}
                />
              </label>
            </div>

            <div className="flex items-center mt-5 ">
              <span className="block mb-1 font-medium text-gray-700">
                Is it operable?
              </span>
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  className="ml-3 form-radio h-5 w-5 text-indigo-600"
                  value="Operable"
                  checked={Operable === "Yes"}
                  onChange={() => ChangeOperable("Yes")}
                />
                <span className="ml-2 ">Yes</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio h-5 w-5 text-indigo-600"
                  value="Operable"
                  checked={Operable === "No"}
                  onChange={() => ChangeOperable("No")}
                />
                <span className="ml-2">No</span>
              </label>
            </div>
            <div className="">
              <span className="block mt-1 font-semibold lg:font-semibold   text-gray-700 mb-4">
                Documentation related to the vehicle's ownership( registration
                and proof of insurance.)
              </span>
              <input
                type="file"
                onChange={handleFileChange}
                className="  py-2 px-1  lg:px-4 "
              />
              {selectedFile && (
                <div className="mt-4">
                  <p className="font-medium">
                    Selected File: {selectedFile.name}
                  </p>
                  <button
                    onClick={handleUpload}
                    className="mt-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-7 lg:mt-14 flex justify-start space-x-24 ml-1 ">
          {/* <Link> */}
            <button className="bg-red-500 hover:bg-red-600 text-white font-bold  py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap"
            onClick={HandleSubmit}
            >
              {buttonLabel1}
            </button>
          {/* </Link> */}
        </div>
      </div>
    </DriverInterface>
  );
}
