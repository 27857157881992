import React, { useState } from "react";
import MyVehicles from "./MyVehicles";

export default function MyVehicleDelivered() {
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTimeOption, setSelectedTimeOption] = useState("");
  const [selectedShowOption, setSelectedShowOption] = useState("");

  const handleSearchChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = (event) => {
    setSelectedTimeOption(event.target.value);
  };
  const handleShowChange = (event) => {
    setSelectedShowOption(event.target.value);
  };
  const Explanation =
    'These orders have been delivered and the transaction has been completed. If you have a similar listing, you may copy the vehicles of an order to Quick Transport Line using the "copy to CD" action, but the order will always remain in the "Delivered" tab until it has been archived. To reduce the number of records in this tab, you may move these orders into the "Archived" tab by taking the "Archive" action next tothe order.';
  const array = [1, 2, 3, 4];

  return (
    <MyVehicles>
      <div className="mt-8 px-6 hidden lg:block bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div>{Explanation}</div>
      </div>
      <div className="my-8 px-6 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
          <div className="flex sm:justify-between w-full">
            <div className="flex sm:mb-2 items-center">
              <span className="text-sm">Search for</span>
              <select
                value={selectedOption}
                onChange={handleSearchChange}
                className=" text-sm ml-1 block p-1 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option className="" value="" disabled>
                  Select
                </option>
                <option value="option1">City</option>
                <option value="option2">Driver</option>
                <option value="option3">Country</option>
              </select>
            </div>

            <div className="flex sm:mb-2 items-center">
              <input
                type="text"
                className=" bg-gray-300 w-28 px-1 text-sm py-1 rounded-l-md"
              ></input>
              <button className="text-sm bg-red-800 text-white ml-0 py-1 px-3 rounded-r-md">
                GO
              </button>
            </div>
          </div>
          <div className="flex sm:justify-between w-full">
            <div className="flex sm:mb-2 items-center">
              <span className="text-sm">Tiemframe</span>
              <select
                value={selectedTimeOption}
                onChange={handleChange}
                className="block text-sm ml-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="option1">3 months</option>
                <option value="option2">6 month</option>
                <option value="option3">1 year</option>
              </select>
            </div>
            <div className="ml:0 lg:ml-2 flex sm:mb-2 items-center">
              <span className="text-sm">Show</span>
              <select
                value={selectedShowOption}
                onChange={handleShowChange}
                className="block text-sm ml-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="" disabled>
                  10
                </option>
                <option value="option1" selected>
                  20
                </option>
                <option value="option2">50</option>
                <option value="option3">100</option>
              </select>
            </div>
          </div>
          <div className="flex sm:justify-between w-full">
            <div className="ml-0 lg:ml-2 flex sm:mb-2 items-center">
              <span className="text-sm sm:pr-9">Page</span>
              <select
                value={selectedShowOption}
                onChange={handleShowChange}
                className="block text-sm mx-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">select</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span>of 1</span>
            </div>
            <div className="ml-0 lg:ml-2">
              <button className="bg-red-800 text-sm text-white ml-0 py-1 px-3 rounded-md">
                GO
              </button>
            </div>
          </div>
        </div>
        <hr className="border-gray-500" />

        {/* content here */}

        <div className="mt-6 overflow-x-auto" id="Container">
          <div className="text-sm font-semibold hidden lg:flex flex-wrap items-center justify-between">
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Pickup</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Delivery</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Vehicle / Order ID</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Carrier / Pay</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Ship On / Modified</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Actions</span>
            </div>
          </div>

          {array.map((option, index) => (
            <>
              <div className=" flex flex-wrap mb-7 items-center justify-between">
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Pickup:{" "}
                  </span>
                  <span className="text-sm">
                    FL-Hollywood 33020 Miami Fort Lauder- dale Pompano Beach
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Delivery:{" "}
                  </span>
                  <span className="text-sm">
                    FL-Hollywood 33020 Miami Fort Lauder- dale Pompano Beach
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Vehicle / Order ID:{" "}
                  </span>
                  <span className="text-sm">
                    2014 Audi Q5
                    <br />
                    Order ID: Q5. txt
                    <br />
                    786.7070.849
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Carrier / Pay:{" "}
                  </span>
                  <span className="text-sm">
                    LDM Max Transport LLC
                    <br />
                    FL Only <br />
                    $65.00/$65.00 COD
                    <br />
                    (Cash/Certified Funds)
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Ship On / Modified:{" "}
                  </span>
                  <span className="text-sm">
                    <span className="font-semibold">
                      09/18/23
                      <br />
                      Modified:
                    </span>{" "}
                    09/19/23
                    <br />
                    2:02 am PST
                  </span>
                </div>
                <div className="flex flex-col w-full sm:w-40">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Actions:
                  </span>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-5 rounded-md">
                    action
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-5 rounded-md">
                    view
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-5 rounded-md">
                    copy to cd
                  </button>
                </div>
              </div>
              <hr className="border-gray-500" />
            </>
          ))}
        </div>
      </div>
    </MyVehicles>
  );
}
