import React, { Children } from "react";
import Card from "./Location";
import Interface from "../../Rcompo/Interface";
import { Link } from "react-router-dom";

export default function PostShipment({ children }) {
  return (
    <Interface>
      <div className="mx-7 space-x-7 md:mx-10 lg:mx-32 mt-4 lg:mt-10 bg-slate-200 p-3 px-10 rounded-t-lg">
        <Link to="/DispatchOnly">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="select"
              className="form-radio h-5 w-5 text-indigo-600"
              value="option2"
            />
            <span className="ml-1 lg:ml-3">Dispatch Only</span>
          </label>
        </Link>
        <Link to="/Post&StorePDD">
          <label className="inline-flex items-center">
            <input
              type="radio"
              name="select"
              className="form-radio h-5 w-5 text-indigo-600"
              value="option2"
            />
            <span className="ml-1 lg:ml-3">
              Post & Store Private Data for Dispatch
            </span>
          </label>
        </Link>
      </div>
      <main className="mb-10">{children}</main>
    </Interface>
  );
}
