import React, { useEffect,useState } from "react";
import Interface from "../../Rcompo/Interface";
import { Link } from "react-router-dom";
import globalAPIAddress from "../GlobalPages/GlobalVar";
import axios from "axios";

export default function Preview() {
  const [items,setItems]=useState([])
  useEffect(()=>{
    const fetchItems=async()=>{
      try{
        const response = await axios.get(`${globalAPIAddress}/Preview`);
      setItems(response.data)
      console.log(response.data)
  }
    catch (error) {
      console.error(error);
    }
  };
  fetchItems();
  },[])
  return (
    <Interface>
      <div className="mx-7 md:mx-10 lg:mx-32 border  mb-8 h-fit mt-0 lg:mt-10  bg-white  shadow-2xl rounded-md lg:rounded-xl ">
        <div
          className="text-sm md:text-xl sm:text-lg px-10 pt-4 lg:pt-5 font-semibold h-fit py-2 mb-4   rounded-tr-xl rounded-tl-xl"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          Preview
        </div>
        <div  className="block lg:grid px-10 lg:grid-cols-4">
          <div className="border mt-6  bg-white mr-2 lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className=" flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-2   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Location</p>
              <button className="text-blue-900 text-xs  font-bold  ml-auto">
                    EDIT
                  </button>
            </div>
            <div>
                </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Pickup Location:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.Location}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Destination:{" "}
                </span>
                <span className="text-sm lg:text-md"> {items.Destination}</span>
              </div>

              <div className="flex justify-between mb-2">
                <div>
                  <span className="lg:text-md text-sm ml-4 font-semibold">
                    Contact No:{" "}
                  </span>
                  <span className="text-sm lg:text-md">{items.Number}</span>
                </div>
                <div></div>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Transport Type:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.TransportType}</span>
              </div>
            </div>
            
          </div>

          <div className="border mt-6  bg-white ml-2 lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Company Information</p>
              <button className="text-blue-900 text-xs  font-bold  ml-auto">
                    EDIT
                  </button>
            </div>
            <div className="py-2 px-4">
              <div className="mb-2">
                <p className="text-sm lg:text-md ml-4 font-semibold">
                  Year: <span className="text-sm lg:text-md ">{items.Year}</span>
                </p>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Model:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.Model}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Make:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.Make}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Vin Number:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.Vin}</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Operatable:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.Operatble}</span>
              </div>
            </div>
          </div>

          <div className="border mt-6 mr-2 bg-white lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Shipment details</p>
              <button className="text-blue-900 text-xs  font-bold  ml-auto">
                    EDIT
                  </button>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  From:{" "}
                </span>
                <span>{items.From}</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  To:{" "}
                </span>
                <span>{items.To}</span>
              </div>
            </div>
          </div>

          <div className="border ml-2 mt-6  bg-white lg:col-span-2 ">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">Billing Information</p>
              <button className="text-blue-900 text-xs  font-bold  ml-auto">
                    EDIT
                  </button>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2 ">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Price to Pay:{" "}
                </span>
                <span className="text-sm lg:text-md"> {items.PTC}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  COD/COP:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.COD_P} </span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Balance:{" "}
                </span>
                <span className="text-sm lg:text-md">{items.Balance}</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Payment Method:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
            </div>
          </div>
        </div>
        <Link to="/SearchVehicle">
          <button className="bg-red-500  mb-10  mx-14 lg:mx-10 mt-6 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
            Submit Shipment
          </button>
        </Link>
      </div>
    </Interface>
  );
}
