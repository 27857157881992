import React, { useEffect, useState } from "react";
import Interface from "../Rcompo/Interface";
import { Link } from "react-router-dom";
// import globalAPIAddress from "../GlobalPages/GlobalVar";
import axios from "axios";

export default function VeiwDispatch() {
  const [vehicles, setvehicles] = useState([1, 3]);
  return (
    <Interface>
      <div className="mx-7 md:mx-10 lg:mx-32 border  mb-8 h-fit mt-0 lg:mt-10  bg-white  shadow-2xl rounded-md lg:rounded-xl ">
        <div
          className="text-sm md:text-xl sm:text-lg px-10 pt-4 lg:pt-5 font-semibold h-fit py-2 mb-4   rounded-tr-xl rounded-tl-xl"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          Veiw Dispatch
        </div>
        <div className=" lg:grid px-10 lg:grid-cols-4 flex flex-col py-10">
          <div className="border mt-6  bg-white mr-2 lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className=" flex text-sm md:text-xl sm:text-lg font-semibold h-fit flex-col px-4 py-2 mb-2 rounded-tr-xl rounded-tl-xl"
            >
              <div className="mb-2">
                <span className="text-lg lg:text-md ml-4 font-semibold">
                  Order ID:{" "}
                </span>
                <span className="text-sm lg:text-md">...Past here....</span>
              </div>
              <div className="mb-2">
                <span className="text-lg lg:text-md ml-4 font-semibold">
                  Current Status:{" "}
                </span>
                <span className="text-sm lg:text-md">...Past here....</span>
              </div>
              <div className="mb-2">
                <span className="text-lg lg:text-md ml-4 font-semibold">
                  Last Updated:{" "}
                </span>
                <span className="text-sm lg:text-md">...Past here....</span>
              </div>
              <div className="mb-2">
                <span className="text-lg lg:text-md ml-4 font-semibold">
                  CD reference #:{" "}
                </span>
                <span className="text-sm lg:text-md">...Past here....</span>
              </div>
              <div className="mb-2">
                <span className="text-lg lg:text-md ml-4 font-semibold">
                  Cancellation Reason:{" "}
                </span>
                <span className="text-sm lg:text-md">...Past here....</span>
              </div>
              <div className="mb-2">
                <span className="text-lg lg:text-md ml-4 font-semibold">
                  Notes:{" "}
                </span>
                <span className="text-sm lg:text-md">...Past here....</span>
              </div>
            </div>
          </div>

          <div className="border mt-6  bg-white ml-2 lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">CARRIER INFORMATION</p>
            </div>
            <div className="py-2 px-4">
              <div className="mb-2">
                <p className="text-sm lg:text-md ml-4 font-semibold">
                  <span className="text-sm lg:text-md ">replace</span>
                </p>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  MC Number:{" "}
                </span>
                <span className="text-sm lg:text-md">replace</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Contact:{" "}
                </span>
                <span className="text-sm lg:text-md">replace</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Phone:{" "}
                </span>
                <span className="text-sm lg:text-md">replace</span>
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Phone 2:{" "}
                </span>
                <span className="text-sm lg:text-md">replace</span>
              </div>
            </div>
          </div>

          <div className="border mt-6 mr-2 bg-white lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">AGENT INFORMATION</p>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold"></span>
                <span>replace</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Co. Phone:{" "}
                </span>
                <span>replace</span>
              </div>
            </div>
          </div>

          <div className="border ml-2 mt-6  bg-white lg:col-span-2 ">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">ORDER INFORMATION</p>
              <span className=" text-lg  font-bold  ml-auto">
                Order Id: {""}
                <span>.....replace....</span>
              </span>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2 ">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Dispatch Date:{" "}
                </span>
                <span className="text-sm lg:text-md"> replace</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Pickup Exactly:{" "}
                </span>
                <span className="text-sm lg:text-md">replace </span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Delivery Exactly:{" "}
                </span>
                <span className="text-sm lg:text-md">replace</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Ship Via:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Condition:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Price Listed:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Total Payment to Carrier:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  On Delivery to Carrier:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company** owes Carrier:{" "}
                </span>
                <span className="text-sm lg:text-md">------</span>
              </div>
            </div>
          </div>
          <div className="border mt-6 mr-2 bg-white lg:col-span-4">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">VEHICLE INFORMATION</p>
              <span className=" text-lg  font-bold  ml-auto">
                Vehicles: {""}
                <span>.....replace....</span>
              </span>
            </div>
            <div className="px-4 py-2 border-2 m-10">
              <div className="flex justify-between">
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Year
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Make
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Model
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Type
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Color
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Plate
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">VIN</span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Lot #
                  </span>
                </div>
                <div className="mb-2">
                  <span className="text-sm lg:text-md ml-4 font-bold">
                    Additional Info
                  </span>
                </div>
              </div>
              {vehicles.map((numebr) => (
                <div className="flex justify-between">
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="text-sm lg:text-md ml-4 font-bold">
                      .....replace....
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="border mt-6 mr-2 bg-white lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">PICKUP INFORMATION</p>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Contact Dispatcher: {""}
                </span>
                <span>replace</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Phone:{" "}
                </span>
                <span>replace</span>
              </div>
            </div>
          </div>
          <div className="border mt-6 mr-2 bg-white lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">DELIVERY INFORMATION</p>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Contact Dispatcher: {""}
                </span>
                <span>replace</span>
              </div>
              <div className="mb-3">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Phone:{" "}
                </span>
                <span>replace</span>
              </div>
            </div>
          </div>
          <div className="border mt-6 mr-2 bg-white lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">DISPATCH INSTRUCTIONS</p>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold"></span>
                <span>replace</span>
              </div>
            </div>
          </div>
          <div className="border mt-6 mr-2 bg-white lg:col-span-2">
            <div
              // style={{ backgroundColor: "#F5F5F5" }}
              className="flex text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-4 mb-4   rounded-tr-xl rounded-tl-xl"
            >
              <p className="mx-4">ADDITIONAL TERMS</p>
            </div>
            <div className="px-4 py-2">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold"></span>
                <span>replace</span>
              </div>
            </div>
          </div>
        </div>

        {/* <Link to="/SearchVehicle">
          <button className="bg-red-500  mb-10  mx-14 lg:mx-10 mt-6 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
            Submit Shipment
          </button>
        </Link> */}
      </div>
    </Interface>
  );
}
