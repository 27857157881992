import React from "react";
import Sidebar from "../Components/CommanPages/Sidebar";
import Navbar from "../Components/CommanPages/Navbar";
import Fotter from "../Components/CommanPages/Fotter";

export default function DriverInterface({ children }) {
  return (
    <div className="grid grid-cols-5">
      <div className=" col-span-5">
        <Navbar />
      </div>
      <div className="col-span-5 bg-white">
        <Sidebar page="driver" />
      </div>

      <main className=" col-span-5 ">{children}</main>
      <div className="col-span-5 text-center bg-white relative left-0 bottom-0">
        <Fotter />
      </div>
      {/* xl:px-56 lg:px-32 md:px-10 sm:px-2 */}
    </div>
  );
}
