import React, { useState, useEffect } from "react";
import Interface from "../../Rcompo/Interface";
import axios from "axios";
import globalAPIAddress from "../GlobalPages/GlobalVar";

export default function CompanyProfile() {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/GetCompanyInfo`);
        setData(response.data.company); 
        // alert(response.data)
      } catch (error) {
        // setError('Error fetching company info');
        console.error(error);
      }
    };

    fetchCompanyInfo();
  }, []);
  const initialFormData = {
    companyID: "TMX USA",
    personalPhone: "13231312",
    marketplaces: "Central Dispatch",
    password: "*******",
    businessType: "Dealer",
    ratingAccess: "FULL/Access",
    company: "-----",
    personalAddress: "309 E.White Horse Pike, Galloway, NJ 08205",
    ownerManager: "Vladimir Senko",
    website: "abc@gmail.com",
    hours: "10AM-5PM Mon-Fri",
    timezone: "EST",
    established: "2021",
    description: "Used car dealership",
    dispatchContact: "Walter",
    preferredContactMethod: "Any",
    listings: "091-33943-23",
    local: "091-33943-23",
    email: "adeel@gmail.com",
    ratingsEmailsAt: "adeel@gmail.com",
    dispatchEmailsAt: "adeel@gmail.com",
    // description: "Used car dealership",
    drivingLicenseNumber: "DL 07220U",
  };

  const [acountEditMode, setacountEditMode] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (!acountEditMode) {
      setFormData(initialFormData);
    }
  }, [acountEditMode]);


  const handleAccountEdit = () => {
    setacountEditMode(!acountEditMode);
  };

  const handleAccountChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveAccountDetails = () => {
    axios
      .put(`${globalAPIAddress}/CompanyAccount`, formData)
      .then((res) => {
        alert("Company Saved");
        console.log(res.data);
        setacountEditMode(false);
      })
      .catch((error) => {
        alert("unknown error");
        console.error(error);
      });
  };

  return (
    <Interface>
      <>
        <div className="mx-7 md:mx-10 lg:mx-4 lg:px-8">
          <div className="text-xl sm:text-xl lg:text-3xl mt-6 mb-4 ml-8">
            Company Profile
          </div>
          <div className="border w-full h-fit mt-6 bg-white shadow-2xl rounded-tr-xl rounded-tl-xl">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="text-sm md:text-xl sm:text-lg font-semibold h-fit px-4 py-2 mb-2 bg-slate-300 rounded-tr-xl rounded-tl-xl"
            >
              <div className="flex justify-between">
                <p className="mx-4">Account Information</p>
                <button
                  onClick={() => {
                    if (acountEditMode) {
                      saveAccountDetails();
                    }
                    handleAccountEdit();
                  }}
                  className="text-blue-900 text-xs font-bold hover:underline"
                >
                  {acountEditMode ? "Save" : "Edit"}
                </button>
              </div>
            </div>
            <div className="px-4 py-4">
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company ID:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="companyID"
                    //value{data&&data.companyID}  ////value{formData.companyID}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                  {data ? (
  <span>{data.companyID}</span>
) : (
  <span>{formData.companyID}</span>
)}


                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  marketplaces(s):{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="marketplaces"
                    //value{data?data.marketplaces:formData.marketplaces}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                       {data ? (
                       <span>{data.marketplaces}</span>
                    ):(
                       <span>{formData.marketplaces}</span>
                    )}
                  </span>

                )}
              </div>
              <div className="mb-2">
                <span className="lg:text-md text-sm ml-4 font-semibold">
                  Password:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="password"
                    //value{data?data.password:formData.password}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                         {data ? (
                       <span>{data.password }</span>
                    ):(
                       <span>{formData.password}</span>
                    )}
                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Business Type:
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="businessType"
                    //value{data?data.businessType:formData.businessType}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                       {data ? (
                       <span>{data.businessType}</span>
                    ):(
                       <span>{ formData.businessType}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Rating Access:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="ratingAccess"
                    //value{data?data.ratingAccess:formData.ratingAccess}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                  {data ? (
                       <span>{data.ratingAccess }</span>
                    ):(
                       <span>{formData.ratingAccess}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Company Name:{" "}
                </span>
                {/* {acountEditMode ? (
                  <input
                    type="text"
                    name="company"
                    ////value{formData.company}
                    onChange={handleAccountChange}
                  />
                ) : ( */}
                  <span className="text-sm lg:text-md">    {data ? (
                    <span className="font-bold">{data.company}</span>
                 ):(
                    <span className="font-bold">{formData.company}</span>
                 )}
                 </span>
                {/* )} */}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  personalAddress:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="personalAddress"
                    //value{data?data.personalAddress:formData.personalAddress}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                      {data ? (
                       <span>{data.personalAddress }</span>
                    ):(
                       <span>{ formData.personalAddress}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  ownerManager:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="ownerManager"
                    //value{data?data.ownerManager:formData.ownerManager}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                    {data ? (
                       <span>{data.ownerManager }</span>
                    ):(
                       <span>{ formData.ownerManager}</span>
                    )}

                  </span>
                  
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  website:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="website"
                    //value{data?data.website:formData.website}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">  {data ? (
                    <span>{data.website }</span>
                 ):(
                    <span>{ formData.website}</span>
                 )}
                 </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  personalPhone:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="personalPhone"
                    //value{data?data.personalPhone:formData.personalPhone}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                     {data ? (
                       <span>{data.personalPhone }</span>
                    ):(
                       <span>{formData.personalPhone}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  hours:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="hours"
                    //value{data?data.hours:formData.hours}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">    {data ? (
                    <span>{data.hours }</span>
                 ):(
                    <span>{formData.hours}</span>
                 )}

                 </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  timezone:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="timezone"
                    //value{data?data.timezone:formData.timezone}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                        {data ? (
                       <span>{data.timezone }</span>
                    ):(
                       <span>{ formData.timezone}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  established:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="established"
                    //value{data?data.established:formData.established}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                     {data ? (
                       <span>{data.established }</span>
                    ):(
                       <span>{ formData.established}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  description:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="description"
                    //value{data?data.description:formData.description}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                        {data ? (
                       <span>{data.description}</span>
                    ):(
                       <span>{formData.description}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  dispatchContact:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="dispatchContact"
                    //value{data?data.dispatchContact:formData.dispatchContact}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                  {data ? (
                    <span>{data.dispatchContact}</span>
                  ) : (
                    <span>{formData.dispatchContact}</span>
                  )}
                </span>
                
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  preferredContactMethod:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="preferredContactMethod"
                    //value{data?data.preferredContactMethod:formData.preferredContactMethod}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                    {data ? (
                       <span>{data.preferredContactMethod}</span>
                    ):(
                       <span>{formData.preferredContactMethod}</span>
                    )}

                  </span>

                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  listings:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="listings"
                    //value{data?data.listings:formData.listings}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                       {data ? (
                       <span>{data.listings }</span>
                    ):(
                       <span>{formData.listings}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  local:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name=" local"
                    //value{data?data.local:formData.local}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">    {data ? (
                    <span>{data.local }</span>
                 ):(
                    <span>{formData.local}</span>
                 )}
                 </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  Email:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="email"
                    //value{data?data.email:formData.email}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">    {data ? (
                    <span>{data.email }</span>
                 ):(
                    <span>{ formData.email}</span>
                 )}
                 </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  ratingsEmailsAt:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="ratingsEmailsAt"
                    //value{data?data.ratingsEmailsAt:formData.ratingsEmailsAt}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                       {data ? (
                       <span>{data.ratingsEmailsAt}</span>
                    ):(
                       <span>{ formData.ratingsEmailsAt}</span>
                    )}

                  </span>
                )}
              </div>
              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  dispatchEmailsAt:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name="dispatchEmailsAt"
                    //value{data?data.dispatchEmailsAt:formData.dispatchEmailsAt}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                       {data ? (
                       <span>{data.dispatchEmailsAt }</span>
                    ):(
                       <span>{formData.dispatchEmailsAt}</span>
                    )}

                    
                  </span>
                )}
              </div>

              <div className="mb-2">
                <span className="text-sm lg:text-md ml-4 font-semibold">
                  drivingLicenseNumber:{" "}
                </span>
                {acountEditMode ? (
                  <input
                    type="text"
                    name=" drivingLicenseNumber"
                    //value{data?data.drivingLicenseNumber:formData.drivingLicenseNumber}
                    onChange={handleAccountChange}
                  />
                ) : (
                  <span className="text-sm lg:text-md">
                    {data ? (
                       <span >{data.drivingLicenseNumber }</span>
                    ):(
                       <span >{formData.drivingLicenseNumber}</span>
                    )}

                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Interface>
  );
}
