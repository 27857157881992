import React, { useEffect, useState } from "react";
import axios from "axios";
import globalAPIAddress from "../../../Components/GlobalPages/GlobalVar";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import MyVehicles from "../MyVehicles";

export default function DeliveredDriverss() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [items, setItems] = useState([]);
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);
  const [selectedTimeOption, setSelectedTimeOption] = useState("");
  const [selectedShowOption, setSelectedShowOption] = useState("");
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/DeliveredDr`);
        setItems(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchItems();
  }, []);

  const handleSearchChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = (event) => {
    setSelectedTimeOption(event.target.value);
  };
  const handleShowChange = (event) => {
    setSelectedShowOption(event.target.value);
  };
  const [DELETEModalIsOpen, setDELETEModalIsOpen] = useState(false);
  const [DeliveredModalIsOpen, setDeliveredModalIsOpen] = useState(false);
  const [selectItem, setSelectedItemId] = useState("");
  const [DeleteItem, setDelteSelectedItemId] = useState("");
  const [DatePicked, setDatePicked] = useState("");
  const [ArchieveItemId, setArchieveItemId] = useState("");

  const openArchiveModal = (itemId) => {
    setArchieveItemId(itemId);
    setArchiveModalIsOpen(true);
  };

  const closeArchiveModal = () => {
    setArchiveModalIsOpen(false);
  };

  const ArchieveThisPlzz = () => {
    axios
      .post(`${globalAPIAddress}/ArchieveBtnHere`, { Id: ArchieveItemId._id })
      .then((res) => {
        navigate("/Archiveds");
        console.log("shlsjsdljsdlzkjzlk");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const closeDeliveredModal = () => {
    setDeliveredModalIsOpen(false);
  };
  const openDELETEModal = (itemId) => {
    setDelteSelectedItemId(itemId);
    setDELETEModalIsOpen(true);
  };

  const closeDELETEModal = () => {
    setDELETEModalIsOpen(false);
  };
  const BtnDelivered = () => {
    axios
      .post(`${globalAPIAddress}/DeliveredShipment`, { itemId: selectItem._id , DatePicked: DatePicked})
      .then((res) => {
        navigate("/Delivereds");
        console.log("Deliveredment successful");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const DeleteButton = () => {
    axios
      .post(`${globalAPIAddress}/Delete`, { Id: DeleteItem._id })
      .then((res) => {
        navigate("/PickUps");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Explanation =
    'These orders have been delivered and the transaction has been completed. If you have a similar listing, you may copy the vehicles of an order to Quick Transport Line using the "copy to CD" action, but the order will always remain in the "Delivered" tab until it has been archived. To reduce the number of records in this tab, you may move these orders into the "Archived" tab by taking the "Archive" action next tothe order.';
  return (
    <MyVehicles>
      <div className="mt-8 px-6 hidden lg:block bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div>{Explanation}</div>
      </div>
      <div className="my-8 px-6 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
          <Modal
            className="my-8 px-6 w-96 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300 modal-top-center"
            isOpen={DeliveredModalIsOpen}
            onRequestClose={closeDeliveredModal}
          >
            {/* Edit modal content */}
            <div className="flex flex-row justify-between mb-8">
            <div className="text-sm">Delivered Shipment !</div>
              <button onClick={closeDeliveredModal}>
                <img
                  src={process.env.PUBLIC_URL + "/images/close.png"}
                  alt="Settings"
                  className="w-5 lg:w-6 mr-3"
                />
              </button>
            </div>
            <div className="mb-4"
        
        >
            {/* Information in containers with boxes */}
            <div className="border border-gray-300 mb-2">
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm bg-slate-300">Summary</div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Delivered: <span className="font-normal">{selectItem.Location}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Delivery: <span className="font-normal"> {selectItem.Destination}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Vehicle Info: <span className="font-normal"> {selectItem.Year} {selectItem.Make} {selectItem.Model}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Order id: <span className="font-normal">{selectItem.Counter}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Company: <span className="font-normal">{selectItem.Company}</span></div>

            </div>
            <div className="ml-2 text-sm">

            <label>Select the Date of Delivered</label><br/>
              <input type="date" onChange={(e)=>{
                setDatePicked(e.target.value)
              }}/>
            </div>
        </div>
            <div className="flex justify-end gap-1">
              <button
                className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                
                onClick={() => BtnDelivered(selectItem)}
              >
                Yes
              </button>
              
              <button
                className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={closeDeliveredModal}
              >
                No
              </button>
            </div>
          </Modal>
           {/* Archie Shipment Plzzz */}
           <Modal
    className="my-8 px-2 bg-white w-96 rounded-md py-4 text-lg shadow-md shadow-gray-300 modal-top-center"
    isOpen={archiveModalIsOpen}
    onRequestClose={closeArchiveModal}
>
    {/* Archive modal content */}
    <div className="flex flex-col justify-between"
    
    >
        <div className="flex flex-row justify-between mb-8">
            <div className="text-sm">Are you Sure you want to Archive !</div>
              <button onClick={closeArchiveModal}>
                <img
                  src={process.env.PUBLIC_URL + "/images/close.png"}
                  alt="Settings"
                  className="w-5 lg:w-6 mr-3"
                />
              </button>
            </div>

        <div className="mb-4" 
        >
            {/* Information in containers with boxes */}
            <div className="border border-gray-300 mb-2">
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm bg-slate-300">Summary</div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Pickup: <span className="font-normal">{ArchieveItemId.Location}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Delivery: <span className="font-normal"> {ArchieveItemId.Destination}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Vehicle Info: <span className="font-normal"> {ArchieveItemId.Year} {ArchieveItemId.Make} {ArchieveItemId.Model}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Order id: <span className="font-normal">{ArchieveItemId.Counter}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Company: <span className="font-normal">{ArchieveItemId.Company}</span></div>

            </div>
        </div>

        <div className="flex justify-center w-full mb-4">
            <button 
                onClick={() => ArchieveThisPlzz(ArchieveItemId)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
                Add to Archive
            </button>
        </div>
    </div>
</Modal>
 
          {/* Archie Shipment Plzzz */}

{/* DELETE MODAL */}
          <Modal
            className="my-8 px-6 w-96 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300 modal-top-center"
            isOpen={DELETEModalIsOpen}
            onRequestClose={closeDELETEModal}
          >
            {/* Edit modal content */}
            <div className="flex flex-row justify-between mb-8">
            <div className="text-sm">Are you Sure you want to Delete !</div>
              <button onClick={closeDELETEModal}>
                <img
                  src={process.env.PUBLIC_URL + "/images/close.png"}
                  alt="Settings"
                  className="w-5 lg:w-6 mr-3"
                />
              </button>
            </div>
            <div className="mb-4"
        
        >
            {/* Information in containers with boxes */}
            <div className="border border-gray-300 mb-2">
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm bg-slate-300">Summary</div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Delivered: <span className="font-normal">{DeleteItem.Location}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Delivery: <span className="font-normal"> {DeleteItem.Destination}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Vehicle Info: <span className="font-normal"> {DeleteItem.Year} {DeleteItem.Make} {DeleteItem.Model}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Order id: <span className="font-normal">{DeleteItem.Counter}</span></div>
                <div className="pl-6 py-1 border border-gray-300 font-bold text-sm">Company: <span className="font-normal">{DeleteItem.Company}</span></div>

            </div>
        </div>
            <div className="flex justify-end gap-1">
              <button
                className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                
                onClick={() => DeleteButton(DeleteItem)}
              >
                Yes
              </button>
              
              <button
                className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded text-sm px-5 py-2 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={closeDELETEModal}
              >
                No
              </button>
            </div>
          </Modal>
          <div className="flex sm:justify-between w-full">
            <div className="flex sm:mb-2 items-center">
              <span className="text-sm">Search for</span>
              <select
                value={selectedOption}
                onChange={handleSearchChange}
                className=" text-sm ml-1 block p-1 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option className="" value="" disabled>
                  Select
                </option>
                <option value="option1">City</option>
                <option value="option2">Driver</option>
                <option value="option3">Country</option>
              </select>
            </div>

            <div className="flex sm:mb-2 items-center">
              <input
                type="text"
                className=" bg-gray-300 w-28 px-1 text-sm py-1 rounded-l-md"
              ></input>
              <button className="text-sm bg-red-800 text-white ml-0 py-1 px-3 rounded-r-md">
                GO
              </button>
            </div>
          </div>
          <div className="flex sm:justify-between w-full">
            <div className="flex sm:mb-2 items-center">
              <span className="text-sm">Tiemframe</span>
              <select
                value={selectedTimeOption}
                onChange={handleChange}
                className="block text-sm ml-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="option1">3 months</option>
                <option value="option2">6 month</option>
                <option value="option3">1 year</option>
              </select>
            </div>
            <div className="ml:0 lg:ml-2 flex sm:mb-2 items-center">
              <span className="text-sm">Show</span>
              <select
                value={selectedShowOption}
                onChange={handleShowChange}
                className="block text-sm ml-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="" disabled>
                  10
                </option>
                <option value="option1" selected>
                  20
                </option>
                <option value="option2">50</option>
                <option value="option3">100</option>
              </select>
            </div>
          </div>
          <div className="flex sm:justify-between w-full">
            <div className="ml-0 lg:ml-2 flex sm:mb-2 items-center">
              <span className="text-sm sm:pr-9">Page</span>
              <select
                value={selectedShowOption}
                onChange={handleShowChange}
                className="block text-sm mx-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">select</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span>of 1</span>
            </div>
            <div className="ml-0 lg:ml-2">
              <button className="bg-red-800 text-sm text-white ml-0 py-1 px-3 rounded-md">
                GO
              </button>
            </div>
          </div>
        </div>
        <hr className="border-gray-500" />

        {/* content here */}

        <div className="mt-6 overflow-x-auto" id="Container">
          <div className="text-sm font-semibold hidden lg:flex flex-wrap items-center justify-between">
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Delivered</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Delivery</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Vehicle / Order ID</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Carrier / Pay</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Ship On / Modified</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Actions</span>
            </div>
          </div>
          {items.map((item, index) => (
            <>
              <div className=" flex flex-wrap mb-7 items-center justify-between">
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Delivered:{" "}
                  </span>
                  <span className="text-sm">{item.Location}</span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Delivery:{" "}
                  </span>
                  <span className="text-sm">{item.Destination}</span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Vehicle / Order ID:{" "}
                  </span>
                  <span className="text-sm">
                    {item.Year} {item.Make} {item.Model} <br />
                    Order ID: {item.Counter}
                    <br />
                    {item.Reg_No}
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Carrier / Pay:{" "}
                  </span>
                  <button className="text-sm text-blue-600 underline">
                    {item.Company}
                    </button>
                    <br />
                    <span>
                    FL Only <br />${item.PTC}/${item.COD_P} COD
                    <br />
                    (Cash/Certified Funds)
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Ship On / Modified:{" "}
                  </span>
                  <span className="text-sm">
                    <span className="font-semibold">
                      {item.To}
                      <br />
                      Modified:
                    </span>{" "}
                    {item.From}
                    <br />
                    2:02 am PST
                    <br/>
                    <p className="font-bold">Status: {" "}<span className="font-normal text-green-600"> {item.status}</span></p>

                  </span>
                </div>
                <div className="flex flex-col  sm:w-40 ">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Actions:
                  </span>
                  <button
                    className="text-sm mb-1 rounded-sm bg-gray-300 py-1 px-2"
                    onClick={() => openArchiveModal(item)}
                  >
                    archieve
                  </button>
                  <button
                    className="text-sm mb-1 rounded-sm bg-gray-300 py-1 px-2"
                    // onClick={() => openDeliveredModal(item)}
                  >
                    view
                  </button>
                  <button
                    className="text-sm mb-1 rounded-sm bg-gray-300 py-1 px-2"
                    // onClick={() => openDeliveredModal(item)}
                  >
                    copy to cd
                  </button>
                  <button
                    className="text-sm mb-1 rounded-sm bg-gray-300 py-1 px-2"
                    // onClick={() => openDeliveredModal(item)}
                  >
                    not delivered
                  </button>
                  <button
                    className="text-sm mb-1 bg-gray-300 rounded-sm py-1 px-2"
                    onClick={() => openDELETEModal(item)}

                  >
                    delete 
                  </button>
                  <button className="text-sm rounded-sm mb-1 bg-gray-300 py-1 px-2 ">
                    view Route
                  </button>
                </div>
              </div>
              <hr className="border-gray-500" />
            </>
          ))}
        </div>
      </div>
      </MyVehicles>
  );
}

