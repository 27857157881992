import React, { useState, useEffect } from "react";
import {  Link ,useNavigate} from "react-router-dom";
import globalAPIAddress from "../GlobalPages/GlobalVar";
import PostShipment from "./PostShipment";
import axios from "axios";
const CarrierInfoDispatchonly = ({
  options1,
  options2,
  Year,
  Make,
  Model,
}) => {
  // const navigate = useNavigate();
  const [Pickup, setPickup] = useState("");
  const [Dest, setDest] = useState("");
  const navigate = useNavigate();
  const [Phonenum, setPhonenum] = useState("");
  const [Transtype, setTranstype] = useState(null);
  const [year, setyear] = useState("");
  const [model, setmodel] = useState("");
  const [vin, setVIN] = useState("");
  const [make, setmake] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [operable, setoperable] = useState(null);
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const [balance, setbalance] = useState(0);
  const [Price, setPrice] = useState(0);
  const [COD, setCOD] = useState("");
  const [Btime, setBT] = useState(0);
  const [BTerm, setBt] = useState(0);
  const [BMethod, setBM] = useState(0);
  const [CODM, setCODM] = useState(0);
  const [CODL, setCODL] = useState(0);
  const [isbalancepositive, setisbalancepositive] = useState(false);
  const [isCODpositive, setisCODpositive] = useState(false);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await axios.get(`${globalAPIAddress}/CompanyOwn`);
        setCompany(response.data.com);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching company:", error);
      }
    }
    fetchCompany();
  }, []);

  useEffect(() => {
    setbalance(Price - COD);
    setisbalancepositive(Price - COD > 0);
    setisCODpositive(COD > 0);
  }, [Price, COD]);
  let handleChange = (e, type) => {
    const value = e.target.value;
    if (type === "Price") {
      setPrice(value);
    } else {
      setCOD(value);
    }
  };

  const handleOptionChange = (value) => {
    setoperable(value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = () => {
    console.log("File uploaded:", selectedFile);
    setSelectedFile(null);
  };

  const handleyearChange = (e) => {
    setyear(e.target.value);
  };

  const handlemodelChange = (e) => {
    setmodel(e.target.value);
  };
  const handlemakeChange = (e) => {
    setmake(e.target.value);
  };

  const handlePickup = (e) => {
    setPickup(e.target.value);
  };

  const handleDest = (e) => {
    setDest(e.target.value);
  };
  const [companyName, setCompanyName] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [companyData, setCompanyData] = useState({
    mcNumber: '',
    contact: '',
    personalAddress: '',
    city: '',
    state: '',
    personalPhone: '',
    name: ''
  });
  const handleSuggestionClick = (selectedCompany) => {
    setCompanyName(selectedCompany.company);
    setCompanyData({
      mcNumber: selectedCompany.mcNumber || '',
      contact: selectedCompany.contact || '',
      personalAddress: selectedCompany.personalAddress || '',
      city: selectedCompany.city || '',
      state: selectedCompany.state || '',
      personalPhone: selectedCompany.personalPhone || '',
      name: selectedCompany.name || ''
    });
    setSuggestions([]); // Clear suggestions after selection
  };

  const handleChange1 = async (e) => {
    const input = e.target.value;
    setCompanyName(input);

    try {
      const response = await axios.get(
        `${globalAPIAddress}/api/companies?name=${input}`
      );
      setSuggestions(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTTOptionChange = (value) => {
    setTranstype(value);
  };
  const HandleSubmit = () => {
    axios
      .post(`${globalAPIAddress}/PostShipment`, {
        cstatus:"Assigned",
        carrier_company:companyName,
        pickup_location: Pickup,
        destinationLoc: Dest,
        Phonenumber: Phonenum,
        TT: Transtype,
        year: year,
        make: make,
        model: model,
        vin: vin,
        operable: operable,
        From: From,
        To: To,
        Balance: balance,
        PTC: Price,
        COD_P: COD,
        COD_P_Method: CODM,
        COD_P_Loc: CODL,
        BPM: BMethod,
        BPT: Btime,
        BPTerm: BTerm,
      })
      .then((response) => {
        alert("abcd");
        console.log(response.data.message);
        navigate("/Listed");
      })
      .catch((error) => {
        alert("Please enter all required fields");
        console.log(error.response.data);
        console.log("idher bhi hai");
      });
  };
  return (
    <PostShipment>
      {/* */}
      <div className="mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 grid grid-cols-2 space-x-10 max-w-full mb-2 lg:mb-0 bg-white shadow-2xl shadow-gray-300 rounded-b-md  ">
        <div className="col-span-1">
          <div className="w-full">
            <h2 className="font-bold text-lg lg:text-xl mb-2 lg:mb-4">
              Carrier Information
            </h2>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-1 font-semibold text-gray-700">
              Company Name
              <span className="text-red-500">*</span>
            </label>
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              value={companyName}
              required
              onChange={handleChange1}
            />
            <ul className="mt-1 rounded-md shadow-lg bg-white divide-y divide-gray-200">
              {companyName &&
                suggestions.map((sug) => (
                  <li
                    key={sug._id}
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSuggestionClick(sug)}
                  >
                    {sug.company}
                  </li>
                ))}
            </ul>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              MC#
              
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter MC#"
                // onChange={(e) => {
                //   setMc(e.target.value);
                // }}
              />
            </label>
          </div>
          <div>
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              Contact
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter Contact"
                value={companyData.personalPhone}
                onChange={(e) => setCompanyData({...companyData, personalPhone: e.target.value})}
              />
            </label>
          </div>
          <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
            Address
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              placeholder="Enter Address"
              value={companyData.personalAddress}
              onChange={(e) => setCompanyData({...companyData, personalAddress: e.target.value})}
            />
          </label>
          {/*cars page */}

          <div className="grid lg:grid-cols-2 gap-4">
            <div className="col-span-1">
              <div className="pr-4">
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  City
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter City"
                    value={companyData.personalAddress}
                    onChange={(e) => setCompanyData({...companyData, personalAddress: e.target.value})}
                  />
                </label>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  Phone Number
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter Phoen Number"
                    value={companyData.personalPhone}
                    onChange={(e) => setCompanyData({...companyData, personalPhone: e.target.value})}
                  />
                </label>
              </div>
            </div>

            <div className="col-span-1">
              <div>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  State
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter State"
                    value={companyData.personalAddress}
                    onChange={(e) => setCompanyData({...companyData, personalAddress: e.target.value})}
                  />
                </label>
              </div>
            </div>
            <label className="block mb-3 col-span-2 lg:mb-5 font-semibold text-gray-700">
              Driver's Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter Driver'sName"
                value={companyData.name}
                onChange={(e) => setCompanyData({...companyData, name: e.target.value})}
              />
            </label>
          </div>

          {/* cars page */}
        </div>
        <div className="col-span-1">
          <div className="w-full">
            <h2 className="font-bold text-lg lg:text-xl mb-2 lg:mb-4">
              Your Company Information
            </h2>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              Company Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                readOnly 
                placeholder="Enter Company Name"
                value={company.company}
                // onChange={(e) => {
                //   setCompanyname(e.target.value);
                // }}
              />
            </label>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              MC#
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter MC#"
                // onChange={(e) => {
                //   setMc(e.target.value);
                // }}
              />
            </label>
          </div>
          <div>
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              Contact
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter Contact"
                readOnly 
                value={company.personalPhone}
                // onChange={(e) => {
                //   setContact(e.target.value);
                // }}
              />
            </label>
          </div>
          <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
            Address
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              placeholder="Enter Address"
              readOnly 
              value={company.personalAddress}
              // onChange={(e) => {
              //   setAddress(e.target.value);
              // }}
            />
          </label>
          {/*cars page */}

          <div className="grid lg:grid-cols-2 gap-4">
            <div className="col-span-1">
              <div className="pr-4">
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  City
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter City"
                    value={company.personalAddress}
                    readOnly 
                    // onChange={(e) => {
                    //   setCity(e.target.value);
                    // }}
                  />
                </label>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  Phone Number
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter Phoen Number"
                    readOnly 
                    value={company.personalPhone}
                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="col-span-1">
              <div>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  State
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter State"
                    value={company.personalAddress}
                    readOnly 
                    // onChange={(e) => {
                    //   setState(e.target.value);
                    // }}
                  />
                </label>
              </div>
            </div>
            <label className="block mb-3 col-span-2 lg:mb-5 font-semibold text-gray-700">
              Driver's Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                value={company.name}
                placeholder="Enter Driver'sName"
                readOnly 
                // onChange={(e) => {
                //   setDname(e.target.value);
                // }}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 max-w-full mb-2 lg:mb-0 bg-white shadow-2xl shadow-gray-300 rounded-b-md  ">
        <div className="w-full">
          <h2 className="font-bold text-lg lg:text-xl mb-2 lg:mb-4">
            Post a Shipment
          </h2>
        </div>
        <h2 className="font-semibold text-lg lg:text-xl mb-2 lg:mb-4">
        Location
        </h2>
        <div className="mb-2 lg:mb-4">
          <label
            htmlFor="dropdown1"
            className="block mb-1 font-medium text-gray-700"
          >
         pickup location
         <span className="text-red-500">*</span>
          </label>
          <select
            id="dropdown1"
            className="w-full h-11 lg:h-16 border-2 border-gray-300 rounded-xl py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
            value={Pickup}
            onChange={handlePickup}
          >
            <option className=" bg-yellow-500" value="" disabled>
              Select a Pickup Location
            </option>
            {options1.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2 lg:mb-4">
          <label
            htmlFor="dropdown2"
            className="block mb-1 font-medium text-gray-700"
          >
            Destination
            <span className="text-red-500">*</span>
          </label>
          <select
            id="dropdown2"
            className="w-full h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
            value={Dest}
            onChange={handleDest}
          >
            <option className="text-sm" value="" disabled>
              Select a Destination
            </option>
            {options2.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
          Enter Number (optional)
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              placeholder="Enter receiver number"
              onChange={(e) => {
                setPhonenum(e.target.value);
              }}
            />
          </label>
        </div>
        <div className="flex items-center my-5 ">
          <span className="block mb-1 font-medium text-gray-700">
            Transport{""}Type:
            <span className="text-red-500">*</span>
          </span>
          <label className="inline-flex items-center mr-1 lg:mr-4">
            <input
              type="radio"
              className="ml-2 lg:ml-3 form-radio h-5 w-5 text-indigo-600"
              value="option1"
              checked={Transtype === "Opened"}
              onChange={() => handleTTOptionChange("Opened")}
            />
            <span className="ml-1 lg:ml-3 ">Open</span>

          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio h-5 w-5 text-indigo-600"
              value="option2"
              checked={Transtype === "Enclosed"}
              onChange={() => handleTTOptionChange("Enclosed")}
            />
            <span className="ml-1 lg:ml-3">Enclosed</span>
          </label>
        </div>

        {/*cars page */}
        <h2 className="font-semibold text-xl mb-4">Cars details</h2>
        <div className="grid lg:grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="pr-4">
              <div className="mb-4">
                <label
                  htmlFor="dropdown1"
                  className="block mb-1 font-semibold text-gray-700"
                >
               Year
               <span className="text-red-500">*</span>
                </label>
                <select
                  id="dropdown1"
                  className=" w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={year}
                  onChange={handleyearChange}
                >
                  <option value="">Select a Year</option>
                  {Year.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Text"
                  className="block mb-1 font-semibold text-gray-700"
                >
                 Model
                 <span className="text-red-500">*</span>
                </label>
                <select
                  id="Text"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={model}
                  onChange={handlemodelChange}
                >
                  <option value="">Select a Model</option>
                  {Model.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="dropdown3"
                  className="block mb-1 font-semibold text-gray-700"
                >
                Make
                <span className="text-red-500">*</span>
                </label>
                <select
                  id="dropdown3"
                  className="w-full  h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  value={make}
                  onChange={handlemakeChange}
                >
                  <option value="">Select a Make</option>
                  {Make.map((option, index) => (
                    <option key={index} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <div>
              <label className="block font-semibold text-gray-700 ">
              VIN (Vehicle Identification Number)
              <span className="text-red-500">*</span>
                <br></br>
                <input
                  className=" w-64  mt-1 h-11 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                  type="text"
                  placeholder="Enter VIN..."
                  onChange={(e) => {
                    setVIN(e.target.value);
                  }}
                />
              </label>
            </div>
            <div className="flex items-center mt-5 ">
              <span className="block mb-1 font-medium text-gray-700">
                Is it operable?
                <span className="text-red-500">*</span>
              </span>
              <label className="inline-flex items-center mr-4">
                <input
                  type="radio"
                  className="ml-3 form-radio h-5 w-5 text-indigo-600"
                  value="year"
                  checked={operable === "Opened"}
                  onChange={() => handleOptionChange("Opened")}
                />
                <span className="ml-2 ">Yes</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio h-5 w-5 text-indigo-600"
                  value="model"
                  checked={operable === "No"}
                  onChange={() => handleOptionChange("No")}
                />
                <span className="ml-2">No</span>
              </label>
            </div>
            <div className="mb-5">
              <span className="block mt-1 font-semibold lg:font-semibold   text-gray-700 mb-4">
                Documentation related to the vehicle's ownership( registration
                and proof of insurance.)
              </span>
              <input
                type="file"
                onChange={handleFileChange}
                className="  py-2 px-1  lg:px-4 "
              />
              {selectedFile && (
                <div className="mt-4">
                  <p className="font-medium">
                    Selected File: {selectedFile.name}
                  </p>
                  <button
                    onClick={handleUpload}
                    className="mt-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg"
                  >
                    Upload
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* cars page */}
        {/* shipemnt date */}
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="col-span-1 lg:col-span-2">
            <h2 className="font-semibold text-xl mb-4">Select a date for shipment:</h2>
            <div className="mb-4">
              <label
                htmlFor="fromDate"
                className="block font-semibold text-lg text-gray-700"
              >
                From:
                <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                id="fromDate"
                name="fromDate"
                className="mt-1 p-3 block w-full h-11  lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                onChange={(e) => {
                  setFrom(e.target.valueAsDate);
                }}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="toDate"
                className="block font-semibold text-lg text-gray-700"
              >
                To:
                <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                id="toDate"
                name="toDate"
                className="mt-1 p-3 block w-full h-11  lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                onChange={(e) => {
                  setTo(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {/* shipemnt date */}
        {/* payment page */}
        <div className="grid grid-cols-4 ">
          <h2 className="font-semibold text-xl mb-4 col-span-4">Payment</h2>
          <div className="mb-4 col-span-4">
            <label
              htmlFor="Price"
              className="block font-semibold text-md lg:text-lg text-gray-700"
            >
              Price to Pay Carrier
              <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <span className="p-2 lg:p-5 bg-gray-300 rounded-l-md">$</span>
              <input
                type="text"
                id="Price"
                name="Price"
                value={Price}
                onChange={(e) => handleChange(e, "Price")}
                className="p-3 block h-11 lg:h-16 w-full border-2 border-gray-300 rounded-r-md shadow-sm focus:ring focus:border-blue-500"
              />
            </div>
          </div>
          <div className="mb-4 col-span-2 mr-5">
            <label
              htmlFor="COD"
              className="block font-semibold  text-sm md:text-lg lg:text-lg text-gray-700"
            >
              COD/COP Amount
              <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <span className="p-2 lg:p-5 bg-gray-300 rounded-l-md">$</span>
              <input
                type="text"
                id="COD"
                name="COD"
                value={COD}
                onChange={(e) => handleChange(e, "COD")}
                className=" p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-r-md shadow-sm focus:ring focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div className="col-span-2">
            <label
              htmlFor="COD"
              className="block font-semibold text-sm md:text-lg lg:text-lg text-gray-700 mb-0 "
            >
              Balance
              <span className="text-red-500">*</span>
            </label>
            <div className="flex">
              <span className="p-2 lg:p-5 bg-gray-300 rounded-l-md">$</span>
              <span className="p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-r-md shadow-sm focus:ring focus:border-blue-500">
                {balance}
              </span>
            </div>
          </div>
          <div className="col-span-2 mt-4">
            {isCODpositive && (
              <div className="mb-4 mr-5">
                <div className="">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    COD/COP Payment Method
                  </label>
                  <select
                    onChange={(e) => {
                      setCODM(e.target.value);
                    }}
                    className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                  >
                    <option disabled>select</option>
                    <option>Cash/Certified Funds</option>
                    <option>Check</option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    COD/COP Location
                  </label>
                  <select
                    onChange={(e) => {
                      setCODL(e.target.value);
                    }}
                    className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                  >
                    <option disabled>select</option>
                    <option>Delivery</option>
                    <option>Pickup</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-2 mt-4">
            {isbalancepositive && (
              <div className="mb-4">
                <div>
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    Balance Payment Method
                  </label>
                  <select
                    onChange={(e) => {
                      setBM(e.target.value);
                    }}
                    className="p-1 lg:p-3 mt-6 lg:mt-0 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                  >
                    <option disabled>select</option>
                    <option>Cash</option>
                    <option>Certified Funds</option>
                    <option>Company Check</option>
                    <option>Comcheck</option>
                    <option>TCH</option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    Balance Payment Time
                  </label>
                  <select
                    onChange={(e) => {
                      setBT(e.target.value);
                    }}
                    className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                  >
                    <option disabled>select</option>
                    <option>Immediately</option>
                    <option>
                      2 Business Days {"("}Quick Pay{")"}
                    </option>
                    <option>5 Business Days</option>
                    <option>10 Business Days</option>
                    <option>15 Business Days</option>
                    <option>30 Business Days</option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-md lg:text-lg text-gray-700">
                    Balance Payment Terms Begin On
                  </label>
                  <select
                    onChange={(e) => {
                      setBt(e.target.value);
                    }}
                    className="p-1 lg:p-3 block w-full h-11 lg:h-16 border-2 border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
                  >
                    <option disabled>select</option>
                    <option>Pickup</option>
                    <option>Delivery</option>
                    <option>Receiving a Signed Bill of Lading</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-7 lg:mt-14 flex justify-between space-x-20 ">
          <Link to="/Shipment-Date">
            <button className="bg-gray-600 hover:bg-gray-500 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap">
              Back
            </button>
          </Link>
          {/* <Link to={Path}> */}
          <button
            onClick={HandleSubmit}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap"
          >
            Submit Shipment
          </button>
          {/* </Link> */}
        </div>
      </div>
      {/* payment page */}
    </PostShipment>
  );
};

export default CarrierInfoDispatchonly;

// useEffect(() => {
//   localStorage.setItem("Transtype", Transtype);
//   localStorage.setItem("option1", Pickup);
//   localStorage.setItem("option2", Dest);
// }, [Transtype, Pickup, Dest]);

// useEffect(() => {
//   const storedTranstype = localStorage.getItem("Transtype");
//   const storedOption1 = localStorage.getItem("Pickup");
//   const storedOption2 = localStorage.getItem("Dest");
//   if (storedTranstype !== null) setTranstype(storedTranstype);
//   if (storedOption1 !== null) setPickup(storedOption1);
//   if (storedOption2 !== null) setDest(storedOption2);
// }, []);
