import React, { useEffect, useState } from "react";
 import MyVehicles from "../Components/DriverPages/MyVehicles";
import axios from "axios";
import globalAPIAddress from "../Components/GlobalPages/GlobalVar";
 import Modal from 'react-modal';

export default function Picked() {
 const [selectedOption, setSelectedOption] = useState("");
  const [items, setItems] = useState([]);
  const [selectedTimeOption, setSelectedTimeOption] = useState("");
  const [selectedShowOption, setSelectedShowOption] = useState("");
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/Pickup`);
        setItems(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchItems();
  }, []);
  const handleSearchChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleChange = (event) => {
    setSelectedTimeOption(event.target.value);
  };
  const handleShowChange = (event) => {
    setSelectedShowOption(event.target.value);
  };
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);
const openViewModal = () => {
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    setViewModalIsOpen(false);
  };

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const openArchiveModal = () => {
    setArchiveModalIsOpen(true);
  };

  const closeArchiveModal = () => {
    setArchiveModalIsOpen(false);
  };
  useEffect(() => {
       const fetchItems = async () => {
           try {
             const response = await axios.get(`${globalAPIAddress}/Dispatched`);
             setItems(response.data);
           } catch (error) {
             console.error(error);
           }
         };
         fetchItems();
       }, []);
  const Explanation =
    'These orders have been delivered and the transaction has been completed. If you have a similar listing, you may copy the vehicles of an order to Quick Transport Line using the "copy to CD" action, but the order will always remain in the "Delivered" tab until it has been archived. To reduce the number of records in this tab, you may move these orders into the "Archived" tab by taking the "Archive" action next tothe order.';

  return (
    <MyVehicles>
      <div className="mt-8 px-6 hidden lg:block bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div>{Explanation}</div>
      </div>
      <div className="my-8 px-6 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
        <Modal isOpen={viewModalIsOpen} onRequestClose={closeViewModal}>
        {/* View modal content */}
         <button onClick={closeViewModal}>Close</button>
       </Modal>

       {/* Edit Modal */}
       <Modal isOpen={editModalIsOpen} onRequestClose={closeEditModal}>
         {/* Edit modal content */}
         <button onClick={closeEditModal}>Close</button>
       </Modal>

       {/* Archive Modal */}
       <Modal isOpen={archiveModalIsOpen} onRequestClose={closeArchiveModal}>
         {/* Archive modal content */}
         <button onClick={closeArchiveModal}>Close</button>
       </Modal>
          <div className="flex sm:justify-between w-full">
            <div className="flex sm:mb-2 items-center">
              <span className="text-sm">Search for</span>
              <select
                value={selectedOption}
                onChange={handleSearchChange}
                className=" text-sm ml-1 block p-1 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option className="" value="" disabled>
                  Select
                </option>
                <option value="option1">City</option>
                <option value="option2">Driver</option>
                <option value="option3">Country</option>
              </select>
            </div>

            <div className="flex sm:mb-2 items-center">
              <input
                type="text"
                className=" bg-gray-300 w-28 px-1 text-sm py-1 rounded-l-md"
              ></input>
              <button className="text-sm bg-red-800 text-white ml-0 py-1 px-3 rounded-r-md">
                GO
              </button>
            </div>
          </div>
          <div className="flex sm:justify-between w-full">
            <div className="flex sm:mb-2 items-center">
              <span className="text-sm">Tiemframe</span>
              <select
                value={selectedTimeOption}
                onChange={handleChange}
                className="block text-sm ml-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="option1">3 months</option>
                <option value="option2">6 month</option>
                <option value="option3">1 year</option>
              </select>
            </div>
            <div className="ml:0 lg:ml-2 flex sm:mb-2 items-center">
              <span className="text-sm">Show</span>
              <select
                value={selectedShowOption}
                onChange={handleShowChange}
                className="block text-sm ml-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="" disabled>
                  10
                </option>
                <option value="option1" selected>
                  20
                </option>
                <option value="option2">50</option>
                <option value="option3">100</option>
              </select>
            </div>
          </div>
          <div className="flex sm:justify-between w-full">
            <div className="ml-0 lg:ml-2 flex sm:mb-2 items-center">
              <span className="text-sm sm:pr-9">Page</span>
              <select
                value={selectedShowOption}
                onChange={handleShowChange}
                className="block text-sm mx-1 py-1 px-2 bg-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
              >
                <option value="">select</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span>of 1</span>
            </div>
            <div className="ml-0 lg:ml-2">
              <button className="bg-red-800 text-sm text-white ml-0 py-1 px-3 rounded-md">
                GO
              </button>
            </div>
          </div>
        </div>
        <hr className="border-gray-500" />

        {/* content here */}

        <div className="mt-6 overflow-x-auto" id="Container">
          <div className="text-sm font-semibold hidden lg:flex flex-wrap items-center justify-between">
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Pickup</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Delivery</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Vehicle / Order ID</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Carrier / Pay</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Ship On / Modified</span>
            </div>
            <div className="w-full sm:w-40 mb-2 sm:mb-0">
              <span>Actions</span>
            </div>
          </div>

          {items.map((item, index) => (
            <>
              <div className=" flex flex-wrap mb-7 items-center justify-between">
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Pickup:{" "}
                  </span>
                  <span className="text-sm">
                    FL-Hollywood 33020 Miami Fort Lauder- dale Pompano Beach
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Delivery:{" "}
                  </span>
                  <span className="text-sm">
                    FL-Hollywood 33020 Miami Fort Lauder- dale Pompano Beach
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Vehicle / Order ID:{" "}
                  </span>
                  <span className="text-sm">
                  {item.Year} {item.Make} {item.Model}{" "}
                    <br />
                    Order ID: Q5. txt
                    <br />
                    {item.Reg_No}
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Carrier / Pay:{" "}
                  </span>
                  <span className="text-sm">
                    LDM Max Transport LLC
                    <br />
                    FL Only <br />
                    $65.00/$65.00 COD
                    <br />
                    (Cash/Certified Funds)
                  </span>
                </div>
                <div className="w-full sm:w-40 mb-2 sm:mb-0">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Ship On / Modified:{" "}
                  </span>
                  <span className="text-sm">
                    <span className="font-semibold">
                      09/18/23
                      <br />
                      Modified:
                    </span>{" "}
                    09/19/23
                    <br />
                    2:02 am PST
                  </span>
                </div>
                <div className="flex flex-col  sm:w-40 ">
                   <span className="text-sm font-semibold lg:hidden inline">
                     Actions:
                   </span>
                   <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                //   onClick={Btnassign}
                  >
                    remind
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                   onClick={openViewModal}>
                    view
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                   onClick={openEditModal}>
                    edit
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md">
                    cancel
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                   onClick={openArchiveModal}>
                    archive
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md">
                    view Route
                  </button>
                </div>
              </div>
              <hr className="border-gray-500" />
            </>
          ))}
        </div>
      </div>
    </MyVehicles>
  );
}

