const options1 = [
  { label: "New York", value: "New York" },
  { label: "Los Angeles", value: "Los Angeles" },
  { label: "Chicago", value: "Chicago" },
  { label: "Houston", value: "Houston" },
  { label: "Phoenix", value: "Phoenix" },
  { label: "Philadelphia", value: "Philadelphia" },
  { label: "San Antonio", value: "San Antonio" },
  { label: "San Diego", value: "San Diego" },
  { label: "Dallas", value: "Dallas" },
  { label: "San Jose", value: "San Jose" }
];

const options2 = [
  { label: "London", value: "London" },
  { label: "Paris", value: "Paris" },
  { label: "Tokyo", value: "Tokyo" },
  { label: "Sydney", value: "Sydney" },
  { label: "Moscow", value: "Moscow" },
  { label: "Berlin", value: "Berlin" },
  { label: "Rome", value: "Rome" },
  { label: "Madrid", value: "Madrid" },
  { label: "Beijing", value: "Beijing" },
  { label: "Toronto", value: "Toronto" }
];


const Year = [
  { label: "2000", value: "Option 1" },
  { label: "2001", value: "Option 2" },
  { label: "2002", value: "Option 3" },
  { label: "2003", value: "Option 4" },
  { label: "2004", value: "Option 5" },
  { label: "2005", value: "Option 6" },
  { label: "2006", value: "Option 7" },
  { label: "2007", value: "Option 8" },
  { label: "2008", value: "Option 9" },
  { label: "2009", value: "Option 10" },
  { label: "2010", value: "Option 11" }
];

const Make = [
  { label: "Honda", value: "Option A" },
  { label: "Toyota", value: "Option B" },
  { label: "BMW", value: "Option C" },
  { label: "Ford", value: "Option D" },
  { label: "Chevrolet", value: "Option E" },
  { label: "Mercedes-Benz", value: "Option F" },
  { label: "Audi", value: "Option G" },
  { label: "Nissan", value: "Option H" },
  { label: "Volkswagen", value: "Option I" },
  { label: "Hyundai", value: "Option J" }
];

const Model = [
  { label: "Civic", value: "Option 1" },
  { label: "Grande", value: "Option 2" },
  { label: "X7", value: "Option 3" },
  { label: "F-150", value: "Option 4" },
  { label: "Silverado", value: "Option 5" },
  { label: "E-Class", value: "Option 6" },
  { label: "A4", value: "Option 7" },
  { label: "Altima", value: "Option 8" },
  { label: "Jetta", value: "Option 9" },
  { label: "Accent", value: "Option 10" },
  { label: "Accord", value: "Option 11" },
  { label: "Camry", value: "Option 12" },
  { label: "3 Series", value: "Option 13" },
  { label: "Rogue", value: "Option 14" },
  { label: "Golf", value: "Option 15" }
];


export { options1, options2, Year, Make, Model };
