import React, { useState, useEffect } from "react";
// import Interface from '../Rcompo/Interface';
import { useNavigate} from "react-router-dom";
import PostShipment from "../Components/ShipperPages/PostShipment";
import axios from "axios";
import globalAPIAddress from "../Components/GlobalPages/GlobalVar";
const AfterSearchDis = ({
  head,
  label,
  label1,
  label2,
  options1,
  options2,
  buttonLabel,
  onSubmitData,
  head1,
  label3,
  label4,
  label5,
  label6,
  Year,
  Make,
  Model,
  buttonLabel1,
  head2,
}) => {
  // const navigate = useNavigate();
  const [Pickup, setPickup] = useState("");
  const [Dest, setDest] = useState("");
  const [Phonenum, setPhonenum] = useState("");
  const [YouCompanyInfo,   setYouCompanyInfo  ] = useState("");
  const [Transtype, setTranstype] = useState(null);
  const navigate = useNavigate();
  const [balance, setbalance] = useState(0);
  const [Price, setPrice] = useState(0);
  const [COD, setCOD] = useState("");
  const [isbalancepositive, setisbalancepositive] = useState(false);
  const [isCODpositive, setisCODpositive] = useState(false);
  const [item, setItems] = useState([]);
  const [company, setCompany] = useState([]);

  useEffect(() => {
    async function fetchCompany() {
      try {
        const response = await axios.get(`${globalAPIAddress}/CompanyOwn`); // Assuming your API endpoint for fetching the company is '/api/company'
        setCompany(response.data.com);
        console.log(response.data) // Assuming the response data structure is { com: companyObject }
      } catch (error) {
        console.error('Error fetching company:', error);
      }
    }
    fetchCompany();
  }, []);
  
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/AfterSearchDispatch`);
        console.log("hello",response.data)
        setItems(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchItems();
  
    setbalance(Price - COD);
    setisbalancepositive(Price - COD > 0);
    setisCODpositive(COD > 0);
  
    localStorage.setItem("Transtype", Transtype);
    localStorage.setItem("option1", Pickup);
    localStorage.setItem("option2", Dest);
  
    const storedTranstype = localStorage.getItem("Transtype");
    const storedOption1 = localStorage.getItem("Pickup");
    const storedOption2 = localStorage.getItem("Dest");
    if (storedTranstype !== null) setTranstype(storedTranstype);
    if (storedOption1 !== null) setPickup(storedOption1);
    if (storedOption2 !== null) setDest(storedOption2);
  
  }, [Price, COD, Transtype, Pickup, Dest]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/AfterEditListings`);
        console.log("hello",response.data)
        setYouCompanyInfo(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchItems();
  
    setbalance(Price - COD);
    setisbalancepositive(Price - COD > 0);
    setisCODpositive(COD > 0);
  
    localStorage.setItem("Transtype", Transtype);
    localStorage.setItem("option1", Pickup);
    localStorage.setItem("option2", Dest);
  
    const storedTranstype = localStorage.getItem("Transtype");
    const storedOption1 = localStorage.getItem("Pickup");
    const storedOption2 = localStorage.getItem("Dest");
    if (storedTranstype !== null) setTranstype(storedTranstype);
    if (storedOption1 !== null) setPickup(storedOption1);
    if (storedOption2 !== null) setDest(storedOption2);
  
  }, [Price, COD, Transtype, Pickup, Dest]);
  
  const DoneShip = () =>{
    axios
    .post(`${globalAPIAddress}/DoneAssign`, {
      
    })
    .then((response) => {
      console.log(response.data.message);
      navigate("/Not Signed");
    })
    .catch((error) => {
      console.log(error.response.data);
      console.log("idher bhi hai");
    });
  }

  
  return (
    <>
      {/* */}
      <div className="mx-7 md:mx-10 lg:mx-32 px-4 lg:px-10 py-4 lg:py-5 grid grid-cols-2 space-x-10 max-w-full mb-2 lg:mb-0 bg-white shadow-2xl shadow-gray-300 rounded-b-md  ">
        <div className="col-span-1">
          <div className="w-full">
            <h2 className="font-bold text-lg lg:text-xl mb-2 lg:mb-4">
              Carrier Information
            </h2>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
Company Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
               value={item.company}
                placeholder="Enter Company Name"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              MC#
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                value={item.established}

                placeholder="Enter MC#"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
          <div>
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              Contact
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                value={item.personalPhone}
                placeholder="Enter Contact"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
          <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
            Address
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              value={item.address}
              placeholder="Enter Address"
              onChange={(e) => {
                setPhonenum(e.target.value);
              }}
            />
          </label>
          {/*cars page */}

          <div className="grid lg:grid-cols-2 gap-4">
            <div className="col-span-1">
              <div className="pr-4">
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  City
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter City"
                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  Phone Number
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                value={item.listings}
                    placeholder="Enter Phoen Number"
                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="col-span-1">
              <div>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  State
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter State"
                value={item.operational_details && item.operational_details.service_area}

                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>
            <label className="block mb-3 col-span-2 lg:mb-5 font-semibold text-gray-700">
              Driver's Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                value={item.ownerManager}

                placeholder="Enter Driver'sName"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>

          {/* cars page */}
        </div>
        <div className="col-span-1">
          <div className="w-full">
            <h2 className="font-bold text-lg lg:text-xl mb-2 lg:mb-4">
              Your Company Information
            </h2>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              Company Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                value={company.company}
                placeholder="Enter Company Name"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
          <div className="mb-2 lg:mb-4">
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              MC#
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter MC#"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
          <div>
            <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
              Contact
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                value={company.personalPhone}
                placeholder="Enter Contact"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
          <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
            Address
            <input
              className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
              type="text"
              value={company.personalAddress}

              
              placeholder="Enter Address"
              onChange={(e) => {
                setPhonenum(e.target.value);
              }}
            />
          </label>
          {/*cars page */}

          <div className="grid lg:grid-cols-2 gap-4">
            <div className="col-span-1">
              <div className="pr-4">
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  City
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter City"
                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  Phone Number
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                value={YouCompanyInfo.number}
                    placeholder="Enter Phoen Number"
                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="col-span-1">
              <div>
                <label className="block mb-3 lg:mb-5 font-semibold text-gray-700">
                  State
                  <input
                    className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                    type="text"
                    placeholder="Enter State"
                    onChange={(e) => {
                      setPhonenum(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>
            <label className="block mb-3 col-span-2 lg:mb-5 font-semibold text-gray-700">
              Driver's Name
              <input
                className="w-full mt-1 h-12 lg:h-16 border-2 border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-500"
                type="text"
                placeholder="Enter Driver'sName"
                onChange={(e) => {
                  setPhonenum(e.target.value);
                }}
              />
            </label>
          </div>
        </div>
        <div className="flex">
        <button className=" bg-red-500  mb-10  mx-14 lg:mx-10 mt-6 hover:bg-red-600 text-white font-bold py-2 lg:py-4 px-2 lg:px-6 rounded whitespace-nowrap"
        onClick={DoneShip}
        >
            Done
          </button>
          </div>
      </div>
      {/* payment page */}
    </>
  );
};

export default AfterSearchDis;