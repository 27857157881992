import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { options1, options2, Year, Make, Model } from "./data";
import Location from "./Components/ShipperPages/Location";
import { useState } from "react";
import Interface from "./Rcompo/Interface";
import SearchVehicle from "./Components/ShipperPages/SearchVehicle";
import DriverInterface from "./Rcompo/DriverInterface";
import FindShipments from "./Components/DriverPages/FindShipments";
import PostDriverVehicle from "./Components/DriverPages/PostDriverVehicle";
import UserPropfile from "./Components/CommanPages/UserPropfile";
import CompanyProfile from "./Components/CommanPages/CompanyProfile";
import MyVehicles from "./Components/DriverPages/MyVehicles";
import MyVehicleDelivered from "./Components/DriverPages/MyVehicleDelivered";
import InterestedDrivers from "./Components/ShipperPages/InterestedDrivers";
import MyShipment from "./Components/ShipperPages/MyShipmet";
import Billings from "./Components/CommanPages/Billings";
import Contracts from "./Components/CommanPages/Contracts";
import InterestedShipper from "./Components/ShipperPages/InteresstedShipper";
import DriverDashboard from "./Components/DriverPages/DriverDashboard";
import ShiperDashboard from "./Components/ShipperPages/ShiperDashboard";
import Network from "./Components/CommanPages/Network";
import Preview from "./Components/ShipperPages/Preview";
import OTPScreen from "./Components/LoginSignups/SignUp/OTP";
import ForgetPassword from "./Components/LoginSignups/Login/Forget";
import OTPPage from "./Components/LoginSignups/Login/OTPPage";
import ChangePassword from "./Components/LoginSignups/Login/ChangePassword";
import Signup from "./Components/LoginSignups/SignUp/Signup";
import LoginSignup from "./Components/LoginSignups/Login/LoginSignup";
import NotSigned from "./MyVehicle/NotSigned";
import Dispatched from "./MyVehicle/Dispatched";
import Picked from "./MyVehicle/Pick";
import Delivered from "./MyVehicle/Delivered";
import Cancell from "./MyVehicle/Cancel";
import PostShipment from "./Components/ShipperPages/PostShipment";
import CarrierInfoDispatchonly from "./Components/ShipperPages/CarrierInfoDispatchonly";
import Listed from "./MyVehicle/Listed";
import AfterSearchDis from "./MyVehicle/AfterSearchDis";
import Company from "./Components/CommanPages/Company";
import ArchieveShipper from "./MyVehicle/ArchieveShipper";
import VeiwDispatch from "./MyVehicle/ViewShip";
import ListedDriver from "./Components/DriverPages/DriverMyVehicle.js/ListedDriver";
import NotSignedDriver from "./Components/DriverPages/DriverMyVehicle.js/NotAssignedDrivers";
import ArchieveDriver from "./Components/DriverPages/DriverMyVehicle.js/ArchievePageDriver";
import DispatchedDriver from "./Components/DriverPages/DriverMyVehicle.js/DispatchedDriver";
import PickUpDriver from "./Components/DriverPages/DriverMyVehicle.js/PickUpDriver";
import DeliveredDriverss from "./Components/DriverPages/DriverMyVehicle.js/DeliveredShimpents";
import CancellDriver from "./Components/DriverPages/DriverMyVehicle.js/CancellPageDriver";
import DispatchedShipper from "./Components/ShipperPages/MyVehicle/Dispatched";
import DeliveredShipper from "./Components/ShipperPages/MyVehicle/Delivered";
import PickupShipper from "./Components/ShipperPages/MyVehicle/Pickup";
import CancelShippers from "./Components/ShipperPages/MyVehicle/Cancelled";
import EditListings from "./MyVehicle/EditListings";

function App() {
  const [sharedData, setSharedData] = useState("");
  const handleDataChange = (newData) => {
    setSharedData(newData);
  };
  return (
    <Router>
      <div>
        {/* Navbar */}
        {/* <div >
          <Interface/>
        </div> */}
        <Routes>
        <Route path="/Company/:compId" element={<Company/>} />
          <Route exact path="/" element={<LoginSignup />} />
          <Route exact path="/Signup" element={<Signup />} />
          <Route
            path="/Interface"
            element={((<Interface />), (<ShiperDashboard />))}
          />
          <Route
            path="/DriverInterface"
            element={((<DriverInterface />), (<DriverDashboard />))}
          />
          <Route path="/DriverDashboard" element={<DriverDashboard />} />
          <Route path="/ShipperDashboard" element={<ShiperDashboard />} />
          <Route path="IntrestedShipper" element={<InterestedShipper />} />
          <Route
            path="MyVehicles"
            element={((<MyVehicles />), (<MyVehicleDelivered />))}
          />
          <Route
            exact
            path="/PostVehicle"
            element={
              <PostDriverVehicle
                sharedData={sharedData}
                head1="Cars details"
                label3="Year"
                label4="Model"
                label5="Make"
                label6="VIN (Vehicle Identification Number)"
                label7="Registration No."
                label8="Truck Size?"
                Year={Year}
                Make={Make}
                Model={Model}
                Opt={options1}
                buttonLabel1="Confirm Details"
              />
            }
          />
          <Route
            exact
            path="/PostShipment"
            element={
              ((<PostShipment />),
              (
                <Location
                  options1={options1}
                  options2={options2}
                  Year={Year}
                  Make={Make}
                  Model={Model}
                />
              ))
            }
          />
          <Route
            exact
            path="/DispatchOnly"
            element={
              <CarrierInfoDispatchonly
                options1={options1}
                options2={options2}
                buttonLabel="Confirm Location"
                Year={Year}
                Make={Make}
                Model={Model}
             
              />
            }
          />

          <Route
            exact
            path="/CompanyDispatch"
            element={
              <AfterSearchDis
                onDataChange={handleDataChange}
                head="Location"
                label="Enter Number (optional)"
                label1="Pickup Location"
                label2="Destination"
                options1={options1}
                options2={options2}
                buttonLabel="Confirm Location"
                sharedData={sharedData}
                head1="Cars details"
                label3="Year"
                label4="Model"
                label5="Make"
                label6="VIN (Vehicle Identification Number)"
                Year={Year}
                Make={Make}
                Model={Model}
                buttonLabel1="Confirm Detail"
                head2="Select a date for shipment:"
              />
            }
          />

<Route
            exact
            path="/EditListings"
            element={
              <EditListings
                onDataChange={handleDataChange}
                head="Location"
                label="Enter Number (optional)"
                label1="Pickup Location"
                label2="Destination"
                options1={options1}
                options2={options2}
                buttonLabel="Confirm Location"
                sharedData={sharedData}
                head1="Cars details"
                label3="Year"
                label4="Model"
                label5="Make"
                label6="VIN (Vehicle Identification Number)"
                Year={Year}
                Make={Make}
                Model={Model}
                buttonLabel1="Confirm Detail"
                head2="Select a date for shipment:"
              />
            }
          />

          {/* <Route exact path="/Post&StorePDD" element={<PostStorePDD />} /> */}
          <Route
            exact
            path="/Post&StorePDD"
            element={
              <Location
                onDataChange={handleDataChange}
                head="Location"
                label="Enter Number (optional)"
                label1="Pickup Location"
                label2="Destination"
                options1={options1}
                options2={options2}
                buttonLabel="Confirm Location"
                sharedData={sharedData}
                head1="Cars details"
                label3="Year"
                label4="Model"
                label5="Make"
                label6="VIN (Vehicle Identification Number)"
                Year={Year}
                Make={Make}
                Model={Model}
                buttonLabel1="Confirm Detail"
                head2="Select a date for shipment:"
              />
            }
          />
          <Route exact path="SearchVehicle" element={<SearchVehicle />} />
          <Route
            exact
            path="/MyShipment"
            element={<MyShipment Sort="Status" />}
          />
          <Route path="InterestedDrivers" element={<InterestedDrivers />} />
          <Route exact path="/MyProfile" element={<UserPropfile />} />
          <Route exact path="/CompanyProfile" element={<CompanyProfile />} />
          <Route
            exact
            path="/FindShipments"
            element={<FindShipments Sort="pickup-location" />}
          />
          <Route path="/Delivered" element={<MyVehicleDelivered />} />
          <Route path="ListedShipper" element={<Listed />} />
          <Route path="Billing" element={<Billings />} />
          <Route path="Contracts" element={<Contracts />} />
          <Route path="Network" element={<Network />} />
          <Route path="Preview" element={<Preview />} />
          <Route path="OTP" element={<OTPScreen />} />
          <Route path="ForgetPassword" element={<ForgetPassword />} />
          <Route path="Forget-OTP" element={<OTPPage />} />
          <Route path="Change-Password" element={<ChangePassword />} />
          <Route path="Not SignedShipper" element={<NotSigned />} />
          <Route path="Dispatched" element={<Dispatched />} />
          <Route path="PickUp" element={<Picked />} />
          <Route path="Delivered" element={<Delivered />} />
          <Route path="Cancelled" element={<Cancell />} />
          <Route path="/ArchieveShipper" element={<ArchieveShipper/>} />
          <Route path="View" element={<VeiwDispatch/>} />
          <Route path="Listeds" element={<ListedDriver/>} />
          <Route path="Not Signeds" element={<NotSignedDriver/>} />
          <Route path="Archiveds" element={<ArchieveDriver/>} />
          <Route path="Dispatcheds" element={<DispatchedDriver/>} />
          <Route path="PickUps" element={<PickUpDriver/>} />
          <Route path="Delivereds" element={<DeliveredDriverss/>} />
          <Route path="Cancelleds" element={<CancellDriver/>} />
          <Route path="/DispatchedShipper" element={<DispatchedShipper/>} />
          <Route path="/DeliveredShipper" element={<DeliveredShipper/>} />
          <Route path="/PickUpShipper" element={<PickupShipper/>} />
          <Route path="/CancelledShipper" element={<CancelShippers/>} />
        </Routes>
      </div>
    </Router>
  );
}
export default App;
