import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from 'react-modal';
import MyVehicles from "../Components/DriverPages/MyVehicles";
import globalAPIAddress from "../Components/GlobalPages/GlobalVar";

export default function Delivered() {
  const [items, setItems] = useState([]);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [archiveModalIsOpen, setArchiveModalIsOpen] = useState(false);
//   const [modalIsOpen, setIsOpen] = React.useState(false);
//   function openModal() {
//     setIsOpen(true);
//   }

//   function closeModal() {
//     setIsOpen(false);
//   }
//   const Btnassign = () =>{openModal()}
const openViewModal = () => {
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    setViewModalIsOpen(false);
  };

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const openDispatchedModal = () => {
    setArchiveModalIsOpen(true);
  };

  const closeArchiveModal = () => {
    setArchiveModalIsOpen(false);
  };
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${globalAPIAddress}/Delivered`);
        setItems(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchItems();
  }, []);

  return (
    <MyVehicles>
      <div className="my-8 px-6 bg-white rounded-lg py-4 text-lg shadow-md shadow-gray-300">
        <div className="mt-6" id="Container">
          <div className="text-sm px-2 lg:pl-7 font-semibold flex items-center justify-start">
            <span className="mr-3 lg:mr-8">Serial</span>
            <span className="">Vehicle Information</span>
          </div>
          <Modal isOpen={viewModalIsOpen} onRequestClose={closeViewModal}>
        {/* View modal content */}
        <button onClick={closeViewModal}>Close</button>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModalIsOpen} onRequestClose={closeEditModal}>
        {/* Edit modal content */}
        <button onClick={closeEditModal}>Close</button>
      </Modal>

      {/* Archive Modal */}
      <Modal isOpen={archiveModalIsOpen} onRequestClose={closeArchiveModal}>
        {/* Archive modal content */}
        <button onClick={closeArchiveModal}>Close</button>
      </Modal>
          {items.map((item, index) => (
            <div key={index}>
              {/* <span className="mr-3 lg:mr-8">{index}</span> */}
              <div className="flex shadow-md px-2 py-1 lg:p-4 shadow-gray-300 items-center justify-start mt-4 ">
                <div className=" mr-1">
                  <span>1</span>
                </div>
                <div className="mr-7 ml-7 lg:mr-14 lg:ml-14">
                  <span className="text-sm">
                    {item.Year} {item.Make} {item.Model}{" "}
                    <span className="font-semibold">Order ID:</span> Q5. txt
                    {item.Reg_No}
                  </span>
                </div>
{/* <div className="flex flex-row"> */}
                  <Link    
                    to="/IntrestedShipper"
                  >
                <div className="flex items-center justify-start text-sm mb-1 bg-red-600 text-white py-1 px-2 lg:px-5 rounded-md">
                    <div className=" w-5 mr-2 pl-1 bg-white rounded-full">
                      <span className="text-black">1</span>
                    </div>
                    Intrested Shippers
                </div>
                  </Link>
                <div className="flex flex-col justify-end sm:w-40 lg:ml-40">
                  <span className="text-sm font-semibold lg:hidden inline">
                    Actions:
                  </span>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                //   onClick={Btnassign}
                  >
                    picked-up
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                  onClick={openViewModal}>
                    view
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                  onClick={openEditModal}>
                    edit
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md">
                    cancel
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md"
                  onClick={openDispatchedModal}
                  >
                    delivered
                  </button>
                  <button className="text-sm mb-1 bg-gray-300 py-1 px-2 rounded-md">
                    view Route
                  </button>
                </div>
              </div>
              </div>
          ))}
        </div>
      </div>
    </MyVehicles>
  );
}
