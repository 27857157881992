import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import globalAPIAddress from "../GlobalPages/GlobalVar";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const [compname, setCompname] = useState("");
  let linkto = "";
  if (props.page === "driver") {
    linkto = "DriverDashboard";
  } else {
    linkto = "ShipperDashboard";
  }
  const [isVisible, setIsVisible] = useState(false);
  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeLink") || ""
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(
    localStorage.getItem("isDropdownOpen") === "true"
  );
  const [isFindShipmentDropdownOpen, setIsFindShipmentDropdownOpen] = useState(
    localStorage.getItem("isFindShipmentDropdownOpen") === "true"
  );
  const [isListed, setIsListed] = useState(
    localStorage.getItem("isListed") === "true"
  );
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setIsFindShipmentDropdownOpen(false);
        setIsListed(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("isDropdownOpen", isDropdownOpen);
    localStorage.setItem(
      "isFindShipmentDropdownOpen",
      isFindShipmentDropdownOpen
    );
    localStorage.setItem("isListed", isListed);
    localStorage.setItem("activeLink", activeLink);
  }, [isDropdownOpen, isFindShipmentDropdownOpen, isListed, activeLink]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleDropdown = (dropdownName) => {
    setIsDropdownOpen(false);
    setIsFindShipmentDropdownOpen(false);
    setIsListed(false);

    if (dropdownName === "main") {
      setIsDropdownOpen(true);
    } else if (dropdownName === "findShipment") {
      setIsFindShipmentDropdownOpen(true);
    } else if (dropdownName === "ListedVehicle") {
      setIsListed(true);
    }
  };

  let options = [];
  let findShipmentOptions = [];
  let ListedVehicle = [];
  let firstoption = "ShipVehicles";
  let secondoption = "Find Shipments";
  let thirdoption = "Resources";

  if (props.page === "shipper") {
    options = ["PostShipment"];
    findShipmentOptions = ["SearchVehicle", "MyShipment"];
    ListedVehicle = ["Maps", "Direction", "Weather", "Support"];
  } else {
    options = ["FindShipments"];
    findShipmentOptions = ["PostVehicle", "MyVehicles"];
    ListedVehicle = ["Maps", "Direction", "Weather", "Support"];
    firstoption = "FindVehicles";
    secondoption = "RegisterVehicle";
    thirdoption = "Resources";
  }
  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${globalAPIAddress}/companysearch`, {
        compname: compname,
      });
      alert("company found");
      const { companyId, route } = response.data;
      navigate(`${route}/${companyId}`);
    } catch (error) {
      alert("company not found");
      console.error(error);
    }
  };
  const handleLinkClick = (option) => {
    if (option === "Maps") {
      window.open("https://www.google.com/maps");
    } else if (option === "Weather") {
      window.open("https://www.weather.gov/");
    } else if (option === "Direction") {
      window.open("https://www.google.com/maps/dir//");
    }
  };

  return (
    <div
      className=" flex lg:flex-row flex-col justify-between lg:px-36 py-3 border-b-2 border-yellow-600 lg:border-b-0"
      ref={dropdownRef}
    >
      <div className="flex flex-row justify-between px-2  ">
        <img
          src={process.env.PUBLIC_URL + "/images/logo-QTL.png"}
          alt="Messages"
          className="lg:h-9 h-9  mr-2  mt-1"
        />
        <div className="lg:hidden flex justify-end ">
          <button
            onClick={toggleVisibility}
            className="text-black focus:outline-none"
          >
            <img
              src={process.env.PUBLIC_URL + "/images/hamburger.png"}
              alt="Messages"
              className="lg:h-7  h-5 lg:w-7 w-4 mt-1.5"
            />
          </button>
        </div>
      </div>
      <div className="">
        <div className={`lg:flex ${isVisible ? "" : "hidden"}`}>
          <div>
            <Link to={`/${linkto}`}>
              <div
                className="my-2 ml-0 lg:ml-10 flex items-center cursor-pointer w-full  lg:w-fit "
                onClick={() => setActiveLink("ListedVehicle")}
              >
                <span
                  style={{ color: "#494C4F" }}
                  className="font-outfit text-14   w-full lg:w-fit  lg:text-16 flex mt-4 lg:mt-0 px-2 border-b-2  border-red-100 lg:border-b-0"
                >
                  Dashboard
                </span>
              </div>
            </Link>
          </div>

          <div
            className=" ml-0 lg:ml-10   flex items-center w-full   lg:w-fit  cursor-pointer "
            onClick={() => toggleDropdown("main")}
          >
            <span
              style={{ color: "#494C4F" }}
              className=" font-outfit text-14 w-full lg:w-fit lg:text-16  px-2  border-b-2  border-red-100 lg:border-b-0"
            >
              {firstoption}
            </span>
            <img
              src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
              alt="Dropdown"
              className="w-5  mr-1 border-b-2  border-red-100 lg:border-b-0"
            />
          </div>
          {isDropdownOpen && (
            <div className="ml-0 lg:ml-40   lg:absolute lg:top-24 lg:right-96 shipvehicle">
              {options.map((option, index) => (
                <Link
                  key={index}
                  to={`/${option}`}
                  onClick={() => setActiveLink(option)}
                >
                  <div
                    className={` border-b shadow-sm rounded-sm border-red-100 w-full lg:w-48 h-10 bg-slate-50   hover:bg-red-600 px-4 py-2 lg:px-2 lg:py-2  hover:text-white ${
                      activeLink === option
                        ? "bg-red-600 text-white"
                        : "bg-gray-100 text-black"
                    }`}
                  >
                    <span className="text-14 lg:text-16 font-outfit">
                      {option}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          )}
          <div
            className="mt-2 lg:mt-1 lg:ml-7 w-full   lg:w-fit flex items-center cursor-pointer"
            onClick={() => toggleDropdown("findShipment")}
          >
            <span
              style={{ color: "#494C4F" }}
              className="font-outfit text-14 w-full lg:w-fit lg:text-16  px-2  border-b-2  border-red-100 lg:border-b-0"
            >
              {secondoption}
            </span>
            <img
              src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
              alt="Dropdown"
              className=" w-5 mr-1 border-b-2  border-red-100 lg:border-b-0"
            />
          </div>
          {isFindShipmentDropdownOpen && (
            <div className="  lg:absolute top-24   right-40 findshipmennt">
              {findShipmentOptions.map((option, index) => (
                <Link
                  key={index}
                  to={`/${option}`}
                  className=""
                  onClick={() => setActiveLink(option)}
                >
                  <div
                    className={`border-b  rounded-sm border-red-100  w-full lg:w-48 h-10 bg-slate-50  hover:bg-red-600 px-4 py-2 lg:px-2 lg:py-2  hover:text-white ${
                      activeLink === option
                        ? "bg-red-600 text-white"
                        : "bg-gray-100"
                    }`}
                  >
                    <span className="text-14 lg:text-16 font-outfit  ">
                      {option}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          )}

          <div
            className=" my-2 lg:mt-3 ml-0 lg:ml-10 flex items-center cursor-pointer"
            onClick={() => toggleDropdown("ListedVehicle")}
          >
            <span
              style={{ color: "#494C4F" }}
              className="font-outfit text-14 w-full lg:w-fit lg:text-16  px-2  border-b-2  border-red-100 lg:border-b-0"
            >
              {thirdoption}{" "}
            </span>
            <img
              src={process.env.PUBLIC_URL + "/images/down-arrow.png"}
              alt="Dropdown"
              className="w-5  mr-1 border-b-2  border-red-100 lg:border-b-0"
            />
          </div>
          {isListed && (
            <div className="ml-0 lg:ml-40   lg:absolute lg:top-24 lg:right-12  Rsources">
              {ListedVehicle.map((option, index) => (
                <Link key={index} onClick={() => handleLinkClick(option)}>
                  <div
                    className={` border-b shadow-sm rounded-sm border-red-100 w-full lg:w-48 h-10 bg-slate-50   hover:bg-red-600 px-4 py-2 lg:px-2 lg:py-2 active:bg-red-600 active:text-white  hover:text-white ${
                      activeLink === option
                        ? "bg-red-600 text-white"
                        : "bg-gray-100"
                    }`}
                  >
                    <span className="text-14 lg:text-16 font-outfit">
                      {option}
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          )}
          <input
            type="text"
            value={compname}
            className="border-2 rounded-md  border-black h-9   mt-2"
            onChange={(e) => setCompname(e.target.value)}
          />
          <button
            type="submit"
            onClick={handleSearch}
            className="bg-red-500 ml-1 p-1  mt-2 rounded-md"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}