import React, { useState } from "react";
import globalAPIAddress from "../../GlobalPages/GlobalVar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export default function Signup() {
  const navigate = useNavigate();
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [Option, SetOption] = useState("");
  const [email, setEmail] = useState("");
  const [number, setnumber] = useState("");
  const [Company, setCompany] = useState("");
  const [Password, setPassword] = useState("");

  const HandleSubmit = () => {
    axios
      .post(`${globalAPIAddress}/Signup`, {
        Fname: Fname,
        Lname: Lname,
        email: email,
        number: number,
        Company: Company,
        Password: Password,
        Option: Option,
      })
      .then((res) => {
        console.log(res.data);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      className="relative w-full h-full "
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpeg)`,
      }}
    >
      <div className="flex justify-center items-center h-auto">
        <div className="text-left lg:w-[1000px] w-full  my-20  mx-auto bg-white p-4 sm:p-8 md:p-10 lg:p-12 xl:p-16 ">
          <h2 className="text-4xl text-center dark:text-black relative sm:pb-10">
            QUICK <span className="font-bold">TRANSPORT</span>LINE
          </h2>
          <p className="px-2 sm:px-10 md:px-20 lg:px-32 xl:px-20 relative text-black">
            Experience how simple and effective Quick Transport Line is for
            shipping cars, You hereby authorize central Dispatch to change you
            automatically every month until you cancel your subscription. After
            your application is accepted, we will contact you with a password.
            All fields marked with * are required.
          </p>
          <h2 className="relative text-black font-semibold px-2 sm:px-10 md:px-20 lg:px-32 xl:px-20 py-7">
            Important Notice!
          </h2>
          <h2 className="text-2xl  font-semibold relative text-black px-2 text-center py-5">
            Sign Up Now
          </h2>
          <form className="flex flex-col justify-center sm:flex-row ">
            <div className="flex flex-col items-center w-full sm:w-1/2 sm:mr-2">
              <div className="flex flex-col">
                <label className="relative text-gray-600 px-2">
                  First Name
                </label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="text"
                  onChange={(e) => {
                    setFname(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="relative text-gray-600 pt-2 px-2">
                  Email Address
                </label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="relative text-gray-600 pt-2 px-2">
                  Enter your number
                </label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="text"
                  onChange={(e) => {
                    setnumber(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="relative text-gray-600 pt-2 px-2">
                  Password
                </label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col items-center w-full sm:w-1/2  sm:ml-2 mt-4 sm:mt-0">
              <div className="flex flex-col">
                <label className="relative text-gray-600 px-2">Last Name</label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="text"
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                />
              </div>
              <select
                className="w-72 h-12 focus:outline-none border-2 bg-gray-100 border-gray-300 rounded-lg mt-8"
                onChange={(e) => SetOption(e.target.value)}
              >
                <option>Select Role</option>
                <option value="Shipper"> Shipper</option>
                <option value="Driver">Driver</option>
              </select>
              <div className="flex flex-col">
                <label className="relative text-gray-600 pt-2 px-2">
                  Company Name
                </label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="text"
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="relative text-gray-600 pt-2 px-2">
                  Confirm Password
                </label>
                <input
                  className="rounded-md w-72 border relative bg-gray-100 p-2 mt-2 focus:bg-gray-200"
                  type="password"
                />
              </div>
            </div>
          </form>
          <div className="px-2 sm:px-10 md:px-20 lg:px-32 xl:px-40  pt-4">
            <button
              className="rounded-md sm:mt-8  w-full py-2 mt-8 mx bg-red-500 hover:bg-yellow-500 font-semibold relative text-white"
              onClick={HandleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
