import React from "react";
import Sidebar from "../Components/CommanPages/Sidebar";
import Navbar from "../Components/CommanPages/Navbar";
import Fotter from "../Components/CommanPages/Fotter";

export default function Interface({ children }) {
  return (
    <div className="grid grid-cols-5">
      <div className="col-span-5">
        <Navbar />
      </div>
      <div className="col-span-5 bg-white ">
        <Sidebar page="shipper" />
      </div>
      <main
        className=" col-span-5 "
        // style={{
        //   backgroundColor: "#F5F5F5",
        // }}
      >
        {children}
      </main>
      <div className="col-span-5 text-center w-full bg-white static left-0 bottom-0">
        <Fotter />
      </div>
    </div>
  );
}
